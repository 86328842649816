import React from "react";
import { Table } from 'reactstrap';


export default function CalucuumTable(coc) {
    let COC_Cate= coc.coc;
    let Awareness=[4];
    Awareness[0]="";
    let Screening=[4];
    Screening[0]="";
    let Diagnosis=[4]
    Diagnosis[0]="";
    let Treatment=[4];
    Treatment[0]="";
    let LongTerm =[4];
    LongTerm[0]="";
    let Health=[4];
    Health[0]="";
    let Others=[4];
    Others[0]="";
    if(COC_Cate !=null)
    {
    if(COC_Cate.length>0)
    {
        for(let i=0; i<COC_Cate.length; i++)
        {
        if(COC_Cate[i].CoC_category == "Awareness")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Awareness[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Awareness[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Awareness[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Awareness[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "Screening")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Screening[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Screening[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Screening[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Screening[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "Diagnosis")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Diagnosis[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Diagnosis[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Diagnosis[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Diagnosis[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "Treatment")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Treatment[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Treatment[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Treatment[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Treatment[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "LongTerm")
        {
            if(COC_Cate[i].reporting_year == "2017")
            LongTerm[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            LongTerm[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            LongTerm[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            LongTerm[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "Health system strenthening")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Health[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Health[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Health[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Health[3] =COC_Cate[i].population_reached;
        }
        else if(COC_Cate[i].CoC_category == "Other")
        {
            if(COC_Cate[i].reporting_year == "2017")
            Others[0] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2018")
            Others[1] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2019")
            Others[2] =COC_Cate[i].population_reached;
            else if(COC_Cate[i].reporting_year == "2020")
            Others[3] =COC_Cate[i].population_reached;
        }

        }
    }
}

    return (<Table size="sm" bordered>
        <thead>
            <tr>
                <th style={{ textAlign: "center", backgroundColor: "#fff" }}>Reporting period</th>
                <td>2017</td>
                <td>2018</td>
                <td>2019</td>
                <td>2020</td>
            </tr>
        </thead>
        <tbody>
            <tr>
            <th style={{ color: "#D2342A" }}>Awareness and prevention</th>
            <td>{Awareness[0]}</td>
            <td>{Awareness[1]}</td>
            <td>{Awareness[2]}</td>
            <td>{Awareness[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#A5247A" }}>Screening</th>
            <td>{Screening[0]}</td>
            <td>{Screening[1]}</td>
            <td>{Screening[2]}</td>
            <td>{Screening[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#82B450" }}>Diagnosis</th>
            <td>{Diagnosis[0]}</td>
            <td>{Diagnosis[1]}</td>
            <td>{Diagnosis[2]}</td>
            <td>{Diagnosis[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#25A0C3" }}>Treatment</th>
            <td>{Treatment[0]}</td>
            <td>{Treatment[1]}</td>
            <td>{Treatment[2]}</td>
            <td>{Treatment[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#FAA73F" }}>Long term & After care</th>
            <td>{LongTerm[0]}</td>
            <td>{LongTerm[1]}</td>
            <td>{LongTerm[2]}</td>
            <td>{LongTerm[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#82B450" }}>Other</th>
            <td>{Others[0]}</td>
            <td>{Others[1]}</td>
            <td>{Others[2]}</td>
            <td>{Others[3]}</td>
            </tr>
            <tr>
            <th style={{ color: "#25A0C3" }}>Health system strenthening</th>
            <td>{Health[0]}</td>
            <td>{Health[1]}</td>
            <td>{Health[2]}</td>
            <td>{Health[3]}</td>
            </tr>
        </tbody>
    </Table>);
}