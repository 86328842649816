import React, { useState } from "react";
import "../App.css";
import imgUrl from "../assets/images/imgUrl.js";
//import { Container, Row, Col, Button } from "reactstrap";
//import MultiSelect from "react-multi-select-component";
//import { Multiselect } from "multiselect-react-dropdown";

import PowerBIDashboard from "./PowerBIDashboard";
import SecondaryNavbarDashboard from "./navbar/SecondaryNavbarDashboard";

function Dashboard(props) {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);

  //console.log(props);

  // const memberOptions = [
  //   { key: "Astellas" },
  //   { key: "Daiichi" },
  //   { key: "Eisai" },
  //   { key: "Merck" },
  //   { key: "Merck Sharp & Dohme" },
  //   { key: "Novartis Pharma AG" },
  //   { key: "Pfizer" },
  //   { key: "Roche Holding AG" },
  //   { key: "Sanofi" },
  //   { key: "Servier" },
  //   { key: "Shionogi" },
  //   { key: "Takeda" },
  // ];

  // const topicOptions = [
  //   { key: "Brain Cancer", cat: "Cancer" },
  //   { key: "General Cardiovascular", cat: "Cardiovascular Disease" },
  //   { key: "General Diabetes", cat: "Diabetes" },
  //   { key: "Mental and Neurological Disorders", cat: "Other" },
  //   { key: "Asthma", cat: "Respiratory Disease" },
  // ];

  // const regionOptions = [
  //   { key: "Africa" },
  //   { key: "Americas" },
  //   { key: "Eastern Mediterranean" },
  //   { key: "Europe" },
  //   { key: "South-East Asia" },
  //   { key: "Western Pacific" },
  // ];

  // const countryOptions = [
  //   { key: "India" },
  //   { key: "Kenya" },
  //   { key: "South Africa" },
  // ];

  // const [memberSelected, setMemberSelected] = useState([]);
  // const [topicSelected, setTopicSelected] = useState(props.topicsSelected);
  // const [regionSelected, setRegionSelected] = useState(props.regionSelected);
  // const [countrySelected, setCountrySelected] = useState(props.countrySelected);
  // const [clearFilterVariable, setClearFilter] = useState(false);

  // const memberDDRef = useRef(null);
  // const topicDDRef = useRef(null);
  // const regionDDRef = useRef(null);
  // const countryDDRef = useRef(null);

  // function clearFilter() {
  //   setClearFilter((prevClearFilterVariable) => prevClearFilterVariable + 1);

  //   memberDDRef.current.resetSelectedValues();
  //   topicDDRef.current.resetSelectedValues();
  //   regionDDRef.current.resetSelectedValues();
  //   countryDDRef.current.resetSelectedValues();
  // }

  const [reportTab, setReportTab] = useState("");
  let activePage = localStorage.getItem("activePage");

  const selectReportTab = (tab) => {
    setReportTab(tab);
  };

  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      {/* <Container style={{ marginTop: 10, marginBottom: 10, display: "none" }}>
        <Row>
          <Col>
            <Multiselect
              id="member-filter"
              options={memberOptions}
              displayValue="key"
              showCheckbox={true}
              value={memberSelected}
              onSelect={setMemberSelected}
              onRemove={clearFilter}
              placeholder="Select Member"
              ref={memberDDRef}
            />
          </Col>
          <Col>
            <Multiselect
              id="topic-filter"
              options={topicOptions}
              displayValue="key"
              groupBy="cat"
              showCheckbox={true}
              value={topicSelected}
              onSelect={setTopicSelected}
              onRemove={clearFilter}
              placeholder="Select Topic"
              ref={topicDDRef}
              selectedValues={topicSelected}
            />
          </Col>
          <Col>
            <Multiselect
              id="region-filter"
              options={regionOptions}
              displayValue="key"
              showCheckbox={true}
              value={regionSelected}
              onSelect={setRegionSelected}
              onRemove={clearFilter}
              placeholder="Select Region"
              ref={regionDDRef}
              selectedValues={regionSelected}
            />
          </Col>
          <Col>
            <Multiselect
              id="country-filter"
              options={countryOptions}
              displayValue="key"
              showCheckbox={true}
              value={countrySelected}
              onSelect={setCountrySelected}
              onRemove={clearFilter}
              placeholder="Select Country"
              ref={countryDDRef}
              selectedValues={countrySelected}
            />
          </Col>
          <Col>
            <Button outline color="danger" onClick={clearFilter}>
              Clear Filter
            </Button>
          </Col>
        </Row>
      </Container> */}
      {activePage !== "covid-19-portal" ? (
        <SecondaryNavbarDashboard reportTab={reportTab} />
      ) : (
        ""
      )}
      <PowerBIDashboard
        //allMembers={props.allMembers}
        //memberSelected={props.memberSelected}
        allTopics={props.allTopics}
        topicSelected={props.topicsSelected}
        allRegions={props.allRegions}
        regionSelected={props.regionSelected}
        allCountries={props.allCountries}
        countrySelected={props.countrySelected}
        allStrategies={props.allStrategies}
        strategySelected={props.strategySelected}
        allBeneficiaries={props.allBeneficiaries}
        beneficiarySelected={props.beneficiarySelected}
        allDataSource={props.allDataSource}
        dataSourceSelected={props.dataSourceSelected}
        //clearFilterVariable={clearFilterVariable}
        layoutSelected={props.layoutSelected}
        selectReportTab={selectReportTab}
      />
      <div className="feedbackIconDiv">
        <a
          href="https://docs.google.com/forms/d/199H0jtinEp8764lwJ8s0EM5liX64nNmr4FgSL-Hoq_c/viewform?edit_requested=true"
          target="_blank"
        >
          <img
            className="img-fluid"
            alt="runningman"
            src={imgUrl.feedback_icon}
            style={{ width: "90%" }}
          />
        </a>
      </div>
    </div>
  );
}

export default Dashboard;
