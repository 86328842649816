import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Spinner,
} from "reactstrap";

import imgUrl from "../../assets/images/imgUrl.js";
import { Parser } from "json2csv";
const FileDownload = require("js-file-download");

const AppNavbar = (props) => {
  //debugger;
  const [exportPDFButtonLabel, setExportPDFButtonLabel] = useState("PDF");
  const [exportCSVButtonLabel, setExportCSVButtonLabel] = useState("CSV");

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const setLandingPage = (page) => {
    localStorage.setItem("landingPageIs", page);
    localStorage.setItem("onClick", "navbarClick");
    if (page === "dashboard") {
      setActivePage("ncdDashboard");
    } else {
      setActivePage("home");
    }
  };

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    //console.log(prevScrollpos);
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-84px";
    }
    prevScrollpos = currentScrollPos;
  };

  const toggleContactUsfUse = (e) => {
    if (
      document
        .getElementById("contactUsOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("contactUsOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("contactUsOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  const toggleSignUpfUse = (e) => {
    if (
      document
        .getElementById("signUpOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("signUpOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("signUpOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };
  const exportFilePDF = () => {
    //setSpinnerLoading(true);
    document
      .getElementById("pdf_download_alert")
      .classList.replace("hide", "show");
    setExportPDFButtonLabel("PDF Export in Progress...");
    let tenantId = "eb42cdeb-b7b5-4a6d-bc1c-d1ebcbfc482e";
    let oauthTokenURL = `https://login.microsoftonline.com/${tenantId}/oauth2/token`;

    let details = {
      grant_type: "password",
      client_id: "004fe472-d42e-40f1-8c91-7e6e3f918535",
      resource: "https://analysis.windows.net/powerbi/api",
      username: "nandkishor@duretech.onmicrosoft.com",
      password: "Azure!8291",
      client_secret: "CUaI67qsynV85Jg2EEW/Nz+g1DnbiWFLwNUK2svdTus=",
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let bearerTokenURL =
      "https://aaopenplatform.accessaccelerated.org/aaop_server/getBearerToken";

    //fetch(oauthTokenURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    //   body: formBody,
    // })
    fetch(bearerTokenURL, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //sessionStorage.setItem("AccessTokenNew", data.access_token);
        //setAccessToken(data.access_token);
        let accessToken = data;
        let reportID = "83c0064a-b1b1-479d-8216-35b9c76ff7ed";
        let reportURL = `https://api.powerbi.com/v1.0/myorg/reports/${reportID}/pages`;

        //console.log(accessToken);

        fetch(reportURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((dataNew) => {
            //console.log(dataNew);
            var pagesV = dataNew.value;
            //let PageName = sessionStorage.getItem("reportPageName");
            let PageDisplayName = localStorage.getItem("reportPageDisplayName");
            PageDisplayName = PageDisplayName.toLowerCase();
            if (PageDisplayName === "topics") {
              PageDisplayName = "topic";
            }
            if (PageDisplayName === "programs") {
              PageDisplayName = "program";
            }
            if (PageDisplayName === "members") {
              PageDisplayName = "member";
            }

            let pagesFormation = [];

            for (let i = 0; i < pagesV.length; i++) {
              let pNumber = pagesV[i].displayName;
              let pName = pagesV[i].Name;
              pNumber = pNumber.toLowerCase();

              if (pNumber.includes(PageDisplayName)) {
                if (
                  pNumber === "topics" ||
                  pNumber === "countries" ||
                  pNumber === "programs" ||
                  pNumber === "members" ||
                  pNumber === "resources"
                ) {
                } else {
                  let newP = { pageName: pName };
                  pagesFormation.push(newP);
                }
              }
            }
            //console.log(pagesFormation);
            if (accessToken != null) {
              //let dataId = "";
              let exportURL = `https://api.powerbi.com/v1.0/myorg/reports/${reportID}/ExportTo`;

              const exportOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                  format: "PDF",
                  powerBIReportConfiguration: {
                    settings: {
                      locale: "en-US",
                      excludeHiddenPages: "true",
                    },
                    pages: pagesFormation,
                  },
                }),
              };

              fetch(exportURL, exportOptions)
                .then((response) => response.json())
                .then((dataExp) => {
                  //console.log(data);
                  let exportId = dataExp.id;

                  if (exportId !== undefined) {
                    var timer = null;
                    let fileExportURL = `https://api.powerbi.com/v1.0/myorg/reports/83c0064a-b1b1-479d-8216-35b9c76ff7ed/exports/${exportId}/file`;

                    const fileExportOptions = {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                      },
                    };

                    console.time();
                    (async function getStatus() {
                      const res = await fetch(fileExportURL, fileExportOptions);
                      const json = await res.blob();
                      console.log("Start Preparing File : ", json);

                      if (json.size > 8000) {
                        FileDownload(json, PageDisplayName + ".pdf");
                        console.log("File Ready to Download: ", json);
                        console.timeEnd();
                        setExportPDFButtonLabel("PDF");
                        document
                          .getElementById("pdf_download_alert")
                          .classList.replace("show", "hide");
                        clearTimeout(timer);
                        //setSpinnerLoading(false);

                        return true;
                      } else {
                        timer = setTimeout(getStatus, 100000);
                      }
                    })();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  //setSpinnerLoading(false);
                });
            }
          })
          .catch((err) => {
            console.log(err);
            //setSpinnerLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        //setSpinnerLoading(false);
      });
  };

  const exportFileCSV = () => {
    //setSpinnerLoading(true); // Show Loader
    // document
    //   .getElementById("csv_download_alert")
    //   .classList.replace("hide", "show");
    setExportCSVButtonLabel("CSV Export in Progress...");
    fetch("https://aaopenplatform.accessaccelerated.org/aaop_server/getCSV")
      //fetch("http://127.0.0.1:5300/getCSV")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(data);
        //console.log(csv);

        FileDownload(csv, "Programs.csv");
        //setSpinnerLoading(false); // Hide Loader
        setExportCSVButtonLabel("CSV");
        // document
        //   .getElementById("csv_download_alert")
        //   .classList.replace("show", "hide");
      })
      .catch((err) => {
        console.log(`${err} while contacting the API.`);
        //setSpinnerLoading(false); // Hide Loader
        // document
        //   .getElementById("csv_download_alert")
        //   .classList.replace("show", "hide");
      });
    //return true;
  };

  return (
    <div className="appnavbar" id="navbar">
      <Navbar color="light" light expand="lg" className="container">
        <NavbarBrand href="/home" onClick={() => setLandingPage("home")}>
          <img
            className="stoptblogoimg"
            alt="logo"
            src={imgUrl.aalogo_new}
            style={{ width: "145px" }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                About Us
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}

            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Our Work
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/* <NavItem
              className={
                localStorage.getItem("activePage") === "ncdDashboard"
                  ? "nav-active"
                  : ""
              }
            >
              <NavLink
                href="/home"
                onClick={() => setLandingPage("dashboard")}
              >
                NCD Dashboard
              </NavLink>
            </NavItem> */}

            <UncontrolledDropdown nav inNavbar className="appbarDropDown">
              <DropdownToggle nav caret>
                NCD Dashboard
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  {/* Programs */}
                  <NavItem>
                    <NavLink
                      href="/programs"
                      onClick={() => setLandingPage("dashboard")}
                    >
                      Programs
                    </NavLink>
                  </NavItem>
                </DropdownItem>

                <DropdownItem>
                  {/* Member Profiles */}
                  <NavItem>
                    <NavLink
                      href="/members"
                      onClick={() => setLandingPage("dashboard")}
                    >
                      Member Profiles
                    </NavLink>
                  </NavItem>
                </DropdownItem>

                <DropdownItem>
                  <NavItem>
                    <NavLink
                      href="/ncds"
                      onClick={() => setLandingPage("dashboard")}
                    >
                      NCD Profiles
                    </NavLink>
                  </NavItem>
                </DropdownItem>

                <DropdownItem>
                  <NavItem>
                    <NavLink
                      href="/countries"
                      onClick={() => setLandingPage("dashboard")}
                    >
                      Country Profiles
                    </NavLink>
                  </NavItem>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            {/* <NavItem>
              <NavLink href="#">Why NCDS?</NavLink>
            </NavItem> */}

            {/* <NavItem>
              <NavLink href="#">Partner Portal</NavLink>
            </NavItem> */}

            {/* <NavItem>
              <NavLink href="#">News & Events</NavLink>
            </NavItem> */}

            <NavItem
              className={
                localStorage.getItem("activePage") === "covid-19-portal"
                  ? "nav-active"
                  : ""
              }
            >
              <NavLink
                href="/covid-19-portal"
                onClick={() => setActivePage("covid-19-portal")}
              >
                COVID-19 PORTAL
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://aaopenplatform.accessaccelerated.org/resource-library"
                onClick={() => setActivePage("resource-library")}
              >
                Resource Library
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href={void 0}
                onClick={() => toggleContactUsfUse()}
                style={{ cursor: "pointer" }}
              >
                Contact
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href={void 0}
                onClick={() => toggleSignUpfUse()}
                className="signupbtn"
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </NavLink>
            </NavItem>

            <NavItem className="visible-phone">
              <NavLink href="https://accessaccelerated.org/">
                {" "}
                Back to Access accelerated
              </NavLink>
            </NavItem>

            <NavItem className="visible-phone">
              <NavLink href="/faqs" onClick={() => setActivePage("faqs-page")}>
                FAQs
              </NavLink>
            </NavItem>

            <NavItem className="visible-phone">
              <NavLink href="/login">Upload Data</NavLink>
            </NavItem>

            <NavLink className="visible-phone">
              <UncontrolledDropdown nav inNavbar className="visible-phone">
                <DropdownToggle nav caret>
                  <div className="d-inline">Export Data</div>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => exportFileCSV()}
                    disabled={exportCSVButtonLabel === "CSV" ? false : true}
                  >
                    <img
                      className=""
                      alt=""
                      src={imgUrl.csv_icon}
                      style={{ width: "20px", marginRight: "2%" }}
                    />{" "}
                    {exportCSVButtonLabel}
                  </DropdownItem>
                  {props.reportTab === "Members" ||
                  props.reportTab === "TOPICS" ||
                  props.reportTab === "Countries" ? (
                    <DropdownItem
                      onClick={() => exportFilePDF()}
                      disabled={exportPDFButtonLabel === "PDF" ? false : true}
                    >
                      <img
                        className=""
                        alt=""
                        src={imgUrl.pdf_icon}
                        style={{ width: "20px", marginRight: "2%" }}
                      />{" "}
                      {exportPDFButtonLabel}
                    </DropdownItem>
                  ) : (
                    ""
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <PowerBIDashboard refs="child"/> */}
            </NavLink>
            {/*<NavItem className="visible-phone">
              <NavLink href="#">COVID-19 PORTAL</NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default AppNavbar;
