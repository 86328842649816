import React, { useState } from "react";
import {
    Container,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Collapse, CardBody,
} from "reactstrap";
import classnames from 'classnames';
import FileUpload from '../components/FileUpload';

const UploadData = () => {
    const [activeTab, setActiveTab] = useState('1'); // Active Tabs
    const [selectedFileType, setFileType] = useState(); // Set state for file Type
    const [userDetail, setUserDetail] = useState({
        email: "",
        password: "",
        userRole: "",
        aa_member: ""
    }); // Set state for file Type

    let name, value;

    const handleFormInput = (e) => {
        name = e.target.name;
        value = e.target.value;

        setUserDetail({...userDetail, [name]:value})
    }

    const changeFileType = (event) => { // For select list
        if (event.target.value !== undefined) {
            setFileType(event.target.value);
        }
    };

    const registerUser = async (e) => {//Register data POST call
        e.preventDefault();

        const { email, password, userRole, aa_member } = userDetail;
    }

    const toggle = tab => { // Tab selection
        if (activeTab !== tab) setActiveTab(tab);
    }
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => setIsOpen(!isOpen);
    return (
        <div>
            <div className="content-top-gap uploadDataPage">
                <Container className="themed-container" fluid={true}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                User
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                Analysis
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <h4 className="tabTitle">File Upload</h4>
                                    <hr></hr>
                                </Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label for="exampleSelect">File Type</Label>
                                        <Input type="select" name="select" id="exampleSelect" onChange={changeFileType}>
                                            <option value="N/A">N/A</option>
                                            <option value="GHP export data">GHP export data</option>
                                            <option value="Covid daily data">Covid daily data</option>
                                            <option value="Covid stringency Data">Covid stringency Data</option>
                                            <option value="Covid response category data">Covid response category data</option>
                                            <option value="Program banner logo">Program banner logo</option>
                                            <option value="Member logo">Member logo</option>
                                            <option value="NCD logo">NCD logo</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <FileUpload type={selectedFileType} />
                            <Row>
                                <Col sm="12">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>File Id</th>
                                                <th>File Name</th>
                                                <th>Upload Status</th>
                                                <th>Upload By</th>
                                                <th>Upload Date</th>
                                                <th>Comment</th>
                                                <th>Action(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <h4 className="tabTitle">File Upload</h4>
                                    <hr></hr>
                                </Col>


                            </Row>
                            <Row>
                                <Col>
                                    <Button className="float-right downloadPDF_btn" color="primary" onClick={toggleCollapse} style={{ marginBottom: '1rem' }}>Create User</Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm="2">
                                    <FormGroup>
                                        <Label for="exampleSelect2">Show</Label>
                                        <Input type="select" name="select" id="exampleSelect2">
                                            <option value="">5</option>
                                            <option value="">10</option>
                                            <option value="">25</option>
                                            <option value="">50</option>
                                            <option value="">100</option>

                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="7"></Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label for="exampleEmail">Search</Label>
                                        <Input type="text" name="email" id="exampleEmail" placeholder="" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">

                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>File Id</th>
                                                <th>File Name</th>
                                                <th>Upload Status</th>
                                                <th>Upload By</th>
                                                <th>Upload Date</th>
                                                <th>Comment</th>
                                                <th>Action(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Collapse isOpen={isOpen}>
                                        <Card className="border-0">
                                            <CardBody className="w-50">
                                                <Form method="POST" className="row">
                                                    <FormGroup className="col-sm-12">
                                                        <Label for="userEmail">User name/Email</Label>
                                                        <Input type="email" name="email" id="userEmail" onChange={handleFormInput} placeholder="with a placeholder" />
                                                    </FormGroup>
                                                    <FormGroup className="col-sm-12">
                                                        <Label for="userPassword">Password</Label>
                                                        <Input type="password" name="password" id="userPassword" onChange={handleFormInput} placeholder="password placeholder" />
                                                    </FormGroup>
                                                    <FormGroup className="col-sm-12">
                                                        <Label for="userRole">Select Role</Label>
                                                        <Input type="select" name="userRole" id="userRole" onChange={handleFormInput}>
                                                            <option value="5">5</option>
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>

                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup className="col-sm-12">
                                                        <Label for="userMember">AA Member</Label>
                                                        <Input type="select" name="aa_member" id="userMember" onChange={handleFormInput}>
                                                            <option value="5">5</option>
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>

                                                        </Input>
                                                    </FormGroup>
                                                    <div className="col-sm-12">
                                                        <Button className="float-right" onClick={registerUser}>Submit</Button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">

                        </TabPane>
                    </TabContent>

                </Container>
            </div>
        </div>
    );
}

export default UploadData;