import React from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import imgUrl from "../../assets/images/imgUrl.js";

const SecondaryNavbar = (props) => {
  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };
  return (
    <div className="secondary-navholder">
      <Container>
        <Row>
          <Col className="d-flex justify-content-between">
            <Nav className="justify-content-center">
              <NavLink href="https://accessaccelerated.org/">
                <img
                  className=""
                  alt=""
                  src={imgUrl.left_arrow_white}
                  style={{ width: "10px", marginRight: "4px" }}
                />{" "}
                Back to Access accelerated
              </NavLink>
              <NavLink
                href="/faqs"
                className="pr-2 faqs-navLink"
                onClick={() => setActivePage("faqs-page")}
              >
                FAQs
              </NavLink>
            </Nav>
            {/* <Nav className="justify-content-end">

              <NavLink className="text-dark uploadTab signupbtn downloadbtnicon pr-4">
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                  <img
                  className=""
                  alt=""
                  src={imgUrl.user_white}
                  style={{ width: "18px"}}
                />{" "}
              </DropdownToggle>
                  <DropdownMenu right>
                  <DropdownItem>
                      welcome@test.com
                    </DropdownItem>
                    <DropdownItem />
                    <DropdownItem>
                      Logout
                    </DropdownItem>
                    
                    
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavLink>
            </Nav> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SecondaryNavbar;
