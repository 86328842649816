import React from "react";
import { Container, Row, Col } from "reactstrap";

import imgUrl from "../assets/images/imgUrl.js";

import Dashboard from "../components/Dashboard";
import { Helmet } from "react-helmet";

function CovidPortal() {
  //const [launchDashboard, setLaunchDashboard] = useState(0);

  //const [layoutSelected, setLayoutSelected] = useState("default");

  // function showDashboardTemplate(templateID) {
  //   console.log(templateID);
  //   if (templateID === "4") {
  //     setLayoutSelected("covid");
  //     setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>COVID-19 Portal - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder masterWrap">
        <Container>
          <Row>
            <Col lg="7">
              <div className="heading">
                <h1 className="partial-underline">AA COVID-19 PORTAL</h1>
              </div>
              <div className="mast-description">
                <p>
                  In 2020, COVID-19 disrupted the status quo, impacting lives
                  and economies alike. The new COVID-19 Portal presents several
                  analytical tools for providing strategic information to
                  members and partners to facilitate the adaptation of their
                  work to address the NCD-burden of LMICs considering the
                  ongoing COVID-19 pandemic.
                </p>
                <p>
                  {/* <Button
                        onClick={() => showDashboardTemplate("4")}
                        className="explorealltempbtn btnexploresmall"
                      >
                        <span>EXPLORE</span>
                      </Button> */}
                  <a
                    href="#explore"
                    className="explorealltempbtn btnexploresmall"
                  >
                    <span>EXPLORE</span>
                  </a>
                </p>
              </div>
            </Col>
            <Col lg="5" className="mastimgholder">
              <div className="mast-imagecontainer">
                <div>
                  <img
                    className="stoptblogoimg"
                    alt="stopTB"
                    src={imgUrl.coronamask}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="dashboardtemplate-holder">
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <p className="text-center sectiontitle">
                    COVID-19 Impact Review
                  </p>
                  <p className="text-center db-description"></p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12" className="covidportallogomainholder">
                <div className="text-center portallogodiv">
                  <a href="/country-profiles#section1">
                    <div className="shadow-sm covidportallogoholder">
                      <img
                        className="portallogoimg"
                        alt="stopTB"
                        src={imgUrl.supplychain}
                      />
                    </div>
                    <p className="covidportallogotext">SUPPLY CHAIN</p>
                  </a>
                </div>
                <div className="text-center portallogodiv">
                  <a href="/country-profiles#section2">
                    <div className="shadow-sm covidportallogoholder">
                      <img
                        className="portallogoimg"
                        alt="stopTB"
                        src={imgUrl.digitalhealth}
                      />
                    </div>
                    <p className="covidportallogotext">Digital Health</p>
                  </a>
                </div>
                <div className="text-center portallogodiv">
                  <a href="/country-profiles#section3">
                    <div className="shadow-sm covidportallogoholder">
                      <img
                        className="portallogoimg"
                        alt="stopTB"
                        src={imgUrl.primaryhealth}
                      />
                    </div>
                    <p className="covidportallogotext">
                      Integrated Primary Health Care
                    </p>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <p className="text-center db-description mt-0">
                    View our{" "}
                    <a href="/country-profiles#more" className="bluelink">
                      country profiles
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="dashboardtemplate-holder" id="explore">
        <Dashboard layoutSelected={"covid"} />
      </div>

      <div className="feedbackIconDiv">
        <a
          href="https://docs.google.com/forms/d/199H0jtinEp8764lwJ8s0EM5liX64nNmr4FgSL-Hoq_c/viewform?edit_requested=true"
          target="_blank"
        >
          <img
            className="img-fluid"
            alt="runningman"
            src={imgUrl.feedback_icon}
            style={{ width: "90%" }}
          />
        </a>
      </div>
    </>
  );
}

export default CovidPortal;
