import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import imgUrl from "../../assets/images/imgUrl.js";

const Footer = (props) => {
  const [signUpConsent, setSignUpConsent] = useState(false);
  const [yourNameErr, setYourNameErr] = useState(false);
  const [yourEmailErr, setYourEmailErr] = useState(false);
  const [yourNameErrCF, setYourNameErrCF] = useState(false);
  const [yourEmailErrCF, setYourEmailErrCF] = useState(false);
  const [yourMessageErr, setYourMessageErr] = useState(false);

  const [contactFormMessage, setContactFormMessage] = useState("");
  const [contactFormMessageClass, setContactFormMessageClass] = useState("");

  const [signUpFormMessage, setSignUpFormMessage] = useState("");
  const [signUpFormMessageClass, setSignUpFormMessageClass] = useState("");

  const toggleTermsOfUse = (e) => {
    //e.preventDefault();
    // document.getElementById("termsOfUseModal").;
    if (
      document
        .getElementById("termsOfUseModalOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("termsOfUseModalOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("termsOfUseModalOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  const closeTermsModal = () => {
    document
      .getElementById("termsOfUseModalOverlay")
      .classList.replace("show-modal-popup", "hide-modal-popup");
  };

  const toggleCookiePolicy = (e) => {
    //e.preventDefault();
    // document.getElementById("termsOfUseModal").;
    if (
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  const closeCookiesModal = () => {
    document
      .getElementById("cookiePolicyModalOverlay")
      .classList.replace("show-modal-popup", "hide-modal-popup");
  };

  const toggleContactUs = (e) => {
    if (
      document
        .getElementById("contactUsOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("contactUsOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("contactUsOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  const closeContactUsModal = () => {
    document
      .getElementById("contactUsOverlay")
      .classList.replace("show-modal-popup", "hide-modal-popup");
  };

  const toggleSignUp = (e) => {
    if (
      document
        .getElementById("signUpOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("signUpOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("signUpOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  const closeSignUpModal = () => {
    document
      .getElementById("signUpOverlay")
      .classList.replace("show-modal-popup", "hide-modal-popup");
  };

  const toggleSignUpConsent = (e) => {
    setSignUpConsent(!signUpConsent);
  };

  const submitNewsletter = async (e) => {
    //e.preventDefault();

    const your_name = document.getElementById("your_name").value;
    const organization_name = document.getElementById("organization_name")
      .value;
    const your_email = document.getElementById("your_email").value;
    // Hidden Values
    const _wpcf7 = document.getElementById("_wpcf7").value;
    const _wpcf7_version = document.getElementById("_wpcf7_version").value;
    const _wpcf7_locale = document.getElementById("_wpcf7_locale").value;
    const _wpcf7_unit_tag = document.getElementById("_wpcf7_unit_tag").value;
    const _wpcf7_container_post = document.getElementById(
      "_wpcf7_container_post"
    ).value;

    if (your_name === "") {
      setYourNameErr(true);
    } else {
      setYourNameErr(false);
    }

    if (your_email === "") {
      setYourEmailErr(true);
    } else {
      setYourEmailErr(false);
    }

    if (your_name !== "" && your_email !== "") {
      // let formDataObj = {};

      // formDataObj["your-name"] = your_name;
      // formDataObj["organization-name"] = organization_name;
      // formDataObj["your-email"] = your_email;
      // formDataObj["_wpcf7"] = _wpcf7;
      // formDataObj["_wpcf7_version"] = _wpcf7_version;
      // formDataObj["_wpcf7_locale"] = _wpcf7_locale;
      // formDataObj["_wpcf7_unit_tag"] = _wpcf7_unit_tag;
      // formDataObj["wpcf7_container_post"] = _wpcf7_container_post;
      // formDataObj["acceptance-4"] = 1;
      // formDataObj["referer-page"] =
      //   "https://accessaccelerated.org/";

      // console.log(formDataObj);

      let formData = new FormData();

      formData.append("_wpcf7", _wpcf7);
      formData.append("_wpcf7_version", _wpcf7_version);
      formData.append("_wpcf7_locale", _wpcf7_locale);
      formData.append("_wpcf7_unit_tag", _wpcf7_unit_tag);
      formData.append("wpcf7_container_post", _wpcf7_container_post);
      formData.append("your-name", your_name);
      formData.append("organization-name", organization_name);
      formData.append("your-email", your_email);
      formData.append("acceptance-4", 1);
      formData.append("referer-page", "https://accessaccelerated.org/");

      //const plainFormData = Object.fromEntries(formData.entries());
      //const formDataJsonString = JSON.stringify(plainFormData);

      const fetchURL =
        "https://accessaccelerated.org/wp-json/contact-form-7/v1/contact-forms/601/feedback";
      const fetchOptions = {
        method: "POST",
        headers: {
          "content-type":
            "multipart/form-data; boundary=----WebKitFormBoundary",
          accept: "application/json, text/javascript, */*; q=0.01",
        },
        body: formData,
      };

      fetch(fetchURL, fetchOptions)
        .then((response) => response.json())
        .then((dataNew) => {
          console.log(dataNew);

          setSignUpFormMessage(dataNew.message);
          if (dataNew.status === "validation_failed") {
            setSignUpFormMessageClass("overallErrorMessage");
          } else if (dataNew.status === "mail_sent") {
            setSignUpFormMessageClass("overallThankYouMessage");
          }
        })
        .catch((err) => {
          console.log(err);
          //setSpinnerLoading(false);
        });
    }
  };

  const submitContactForm = (e) => {
    //e.preventDefault();

    const your_name = document.getElementById("your_name1").value;
    const organization_name = document.getElementById("organization_name1")
      .value;
    const your_email = document.getElementById("your_email1").value;
    const organization_type = document.getElementById("organization_type")
      .value;
    const your_message = document.getElementById("your_message").value;
    // Hidden Values
    const _wpcf7 = document.getElementById("_wpcf7CF").value;
    const _wpcf7_version = document.getElementById("_wpcf7_versionCF").value;
    const _wpcf7_locale = document.getElementById("_wpcf7_localeCF").value;
    const _wpcf7_unit_tag = document.getElementById("_wpcf7_unit_tagCF").value;
    const _wpcf7_container_post = document.getElementById(
      "_wpcf7_container_postCF"
    ).value;

    if (your_name === "") {
      setYourNameErrCF(true);
    } else {
      setYourNameErrCF(false);
    }

    if (your_email === "") {
      setYourEmailErrCF(true);
    } else {
      setYourEmailErrCF(false);
    }

    if (your_message === "") {
      setYourMessageErr(true);
    } else {
      setYourMessageErr(false);
    }

    if (your_name !== "" && your_email !== "") {
      // let formDataObj = {};

      // formDataObj["your-name"] = your_name;
      // formDataObj["organization-name"] = organization_name;
      // formDataObj["your-email"] = your_email;
      // formDataObj["_wpcf7"] = _wpcf7;
      // formDataObj["_wpcf7_version"] = _wpcf7_version;
      // formDataObj["_wpcf7_locale"] = _wpcf7_locale;
      // formDataObj["_wpcf7_unit_tag"] = _wpcf7_unit_tag;
      // formDataObj["wpcf7_container_post"] = _wpcf7_container_post;
      // formDataObj["acceptance-4"] = 1;
      // formDataObj["referer-page"] =
      //   "https://accessaccelerated.org/";

      // console.log(formDataObj);

      let formData = new FormData();

      formData.append("_wpcf7", _wpcf7);
      formData.append("_wpcf7_version", _wpcf7_version);
      formData.append("_wpcf7_locale", _wpcf7_locale);
      formData.append("_wpcf7_unit_tag", _wpcf7_unit_tag);
      formData.append("wpcf7_container_post", _wpcf7_container_post);
      formData.append("your-name", your_name);
      formData.append("organization-name", organization_name);
      formData.append("organization-type", organization_type);
      formData.append("your-email", your_email);
      formData.append("your-message", your_message);
      //formData.append("acceptance-4", 1);
      formData.append("referer-page", "https://accessaccelerated.org/");

      //const plainFormData = Object.fromEntries(formData.entries());
      //const formDataJsonString = JSON.stringify(plainFormData);

      const fetchURL =
        "https://accessaccelerated.org/wp-json/contact-form-7/v1/contact-forms/600/feedback";
      const fetchOptions = {
        method: "POST",
        headers: {
          "content-type":
            "multipart/form-data; boundary=----WebKitFormBoundary",
          accept: "application/json, text/javascript, */*; q=0.01",
        },
        body: formData,
      };

      fetch(fetchURL, fetchOptions)
        .then((response) => response.json())
        .then((dataNew) => {
          console.log(dataNew);

          setContactFormMessage(dataNew.message);
          if (dataNew.status === "validation_failed") {
            setContactFormMessageClass("overallErrorMessage");
          } else if (dataNew.status === "mail_sent") {
            setContactFormMessageClass("overallThankYouMessage");
          }
        })
        .catch((err) => {
          console.log(err);
          //setSpinnerLoading(false);
        });
    }
  };

  return (
    <div>
      <div id="footer" className="mastheader-holder landingPageFooter">
        <footer>
          <Container>
            <Row>
              <Col lg="4" md="4">
                <div className="">
                  <h5 className="text-white text-left sectiontitle">
                    ACCESS ACCELERLATED{" "}
                  </h5>
                  <p className="text-left db-description mt-0 mb-1">
                    Chemin Des Mines 9 P.O. Box 195 1211 Geneva 20, Switzerland
                  </p>
                  <p className="mt-2">
                    <a
                      href={void 0}
                      className="sourcelink text-uppercase mr-4 terms-of-use"
                      onClick={() => toggleTermsOfUse()}
                    >
                      TERMS OF USE
                    </a>
                    <a
                      href={void 0}
                      rel="noopener noreferrer"
                      className="sourcelink text-uppercase mr-4 cookie-policy"
                      onClick={() => toggleCookiePolicy()}
                    >
                      COOKIE POLICY
                    </a>
                  </p>
                  <p className="text-left db-description mb-0 w-100 mt-1 copyright">
                    ©2020 Access Accelerated
                  </p>
                </div>
              </Col>
              <Col lg="3" md="3">
                <div className="organization-container">
                  <h5 className="text-white text-left sectiontitle">
                    THE ORGANIZATION{" "}
                  </h5>
                  <div className="menu-organization-container">
                    <ul id="menu-organization" className="menu">
                      <li
                        id="menu-item-18"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-18"
                      >
                        <a href="https://accessaccelerated.org/about-us/our-mission/">
                          About Us
                        </a>
                      </li>
                      <li
                        id="menu-item-19"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19"
                      >
                        <a href="https://accessaccelerated.org/our-work/overview/">
                          Our Work
                        </a>
                      </li>
                      <li
                        id="menu-item-20"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-20"
                      >
                        <a href="https://accessaccelerated.org/why-ncds/">
                          Why NCDS?
                        </a>
                      </li>
                      <li
                        id="menu-item-21"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-21"
                      >
                        <a href="https://accessaccelerated.org/news-and-events/">
                          News &amp; Events
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="2">
                <div className="engage-container">
                  <h5 className="text-white text-left sectiontitle">ENGAGE </h5>
                  <div className="menu-engage-container">
                    <ul id="menu-engage" className="menu">
                      <li
                        id="menu-item-845"
                        className="contact menu-item menu-item-type-custom menu-item-object-custom menu-item-845"
                      >
                        <a href={void 0} onClick={() => toggleContactUs()}>
                          Contact
                        </a>
                      </li>
                      <li
                        id="menu-item-846"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-846"
                      >
                        <a href="https://www.linkedin.com/company/access-accelerated/jobs/">
                          Career
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a
                    className="twitter"
                    href="https://twitter.com/NCDAccess"
                    target="_blank"
                  ></a>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/access-accelerated"
                    target="_blank"
                  ></a>
                </div>
              </Col>
              <Col lg="3" md="3">
                <div className="">
                  <h5 className="text-white text-left sectiontitle">
                    STAY UPDATED{" "}
                  </h5>
                  <p className="text-left db-description mt-0 w-100">
                    Receive the latest updates on NCD programs, research efforts
                    and advocacy directly to your inbox.
                  </p>

                  <a
                    className="btn footer-sign-up"
                    href={void 0}
                    onClick={() => toggleSignUp()}
                  >
                    Sign UP
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
      <div
        className="sidebar-overlay hide-modal-popup"
        id="termsOfUseModalOverlay"
      >
        <div
          className="w3-sidebar w3-bar-block w3-border-right sidemodalpopup"
          style={{ width: "660px", right: "0" }}
          id="termsOfUseModal"
        >
          <button
            onClick={() => closeTermsModal()}
            className="w3-bar-item w3-large sidebar-close-btn"
          >
            <img
              className="img-fluid"
              alt="runningman"
              src={imgUrl.close_icon}
              style={{ width: "25px" }}
            />
          </button>
          <div className="sidepopup-content-wrap">
            <h1 className="sidePopup-maintitle">TERMS OF USE</h1>
            <p className="sidePopup-content">
              IFPMA (“we”, “us”) owns and operates www.ifpma.org (“the/our
              website”). Any person accessing and/or using the website (“the
              visitor/user”) agrees to the “Terms of Use”, containing the
              conditions, rules and other provisions with respect to the access
              and/or use of our website. If you have questions about the “Terms
              of Use”, please feel free to contact us at admin@ifpma.org.
            </p>
            <div className="content-wrap-sideDiv">
              <h3 className="content-title">CONTENT</h3>
              <h4 className="content-side-title">GENERAL USE</h4>
              <p className="sidePopup-content">
                The material contained on this website is for information only.
                The website is entitled to be served as an information resource
                for the internal and external stakeholders of IFPMA, including
                the general public. Third-Party Websites Links to other websites
                are provided solely as a convenience and therefore imply neither
                IFPMA’s responsibility for, nor its approval of the information
                contained in these websites. Intellectual Property Any use of
                information from the website should have an acknowledgement of
                IFPMA/Third-Party as a source respectively, citing the
                corresponding uniform resource locator (URL). Logo and Name The
                use of IFPMA’s name and/or logo is prohibited without prior
                express authorization from IFPMA. The access and/or use of the
                website does not permit to copy, reproduce, modify, distribute
                or elsehow exploit IFPMA’s name and/or logo except with the
                prior explicit permission. Abuse and Violation Any abuse or
                violations of the Terms of Use and/or Privacy Policy should be
                reported to admin@ifpma.org.
              </p>

              <h4 className="content-side-title">PRIVACY POLICY</h4>
              <p className="sidePopup-content">
                IFPMA is committed to protecting the privacy and security of
                visitors to our website. Outlined below is our online privacy
                policy for information collected through our website. If you
                have questions about these policies, please feel free to contact
                us at admin@ifpma.org.
              </p>

              <h4 className="content-side-title">USE OF COOKIES FILES</h4>
              <p className="sidePopup-content">
                Our website is enhanced by cookie files to personalize and
                customize the experience of our visitors and to support some
                necessary functions. IFPMA collects broad usage statistics using
                Google Analytics, to better understand how our visitors use our
                website, and to help us improve visitors’ experience. Such
                information includes the IP address (where available), browser
                type, and any additional information provided by a browser
                during the use of our website. The statistical data about our
                users’ browsing actions and patterns does not identify any
                individual. You may choose to accept or refuse cookies by
                modifying your browser preferences. If you wish to get notified
                when a cookies are set, this option should also be available in
                your browser settings. Please note that if you reject some or
                all cookies, your experience at this and other websites
                throughout the Internet may not be complete.
              </p>

              <h4 className="content-side-title">PAYMENT TRANSACTIONS</h4>
              <p className="sidePopup-content">
                To enable IFPMA to process any order or registration to an
                event, a secure online payment system may be used to process
                payments using credit card details. IFPMA will not be collecting
                or storing credit card details in our system.
              </p>

              <h4 className="content-side-title">THIRD-PARTY WEBSITES</h4>
              <p className="sidePopup-content">
                Please be aware that other websites to which links may be
                provided, including social media, may collect visitor’s
                personally identifiable information when accessed. The
                information collection practices of other websites linked to
                IFPMA’s website are not covered by this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sidebar-overlay hide-modal-popup"
        id="cookiePolicyModalOverlay"
      >
        <div
          className="w3-sidebar w3-bar-block w3-border-right sidemodalpopup"
          style={{ width: "660px", right: "0" }}
          id="cookiePolicyModal"
        >
          <button
            onClick={() => closeCookiesModal()}
            className="w3-bar-item w3-large sidebar-close-btn"
          >
            <img
              className="img-fluid"
              alt="runningman"
              src={imgUrl.close_icon}
              style={{ width: "25px" }}
            />
          </button>
          <div className="sidepopup-content-wrap">
            <h1 className="sidePopup-maintitle">COOKIE POLICY</h1>
            <p className="sidePopup-content">
              Some of our web pages utilise “cookies”. A “cookie” is a small
              text file that may be used, for example, to collect information
              about web site activity. Some cookies and other technologies may
              serve to recall Personal Information previously indicated by a web
              user. Most browsers allow you to control cookies, including
              whether or not to accept them and how to remove them.
            </p>
            <p className="sidePopup-content">
              Some cookies are necessary for the operation of our website, if
              you choose to block them some aspects of the site may not work for
              you. Non necessary cookies are only set when you have given your
              explicit consent to their use. Such cookies included those set by
              our statistics package Google Analytics.
            </p>

            <p className="sidePopup-content">
              We are committed to privacy and support current industry
              initiatives to preserve individual privacy rights on the Internet.
              Protecting your privacy on-line is an evolving area and this
              website will constantly evolve to meet these demands.
            </p>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay hide-modal-popup" id="contactUsOverlay">
        <div
          className="w3-sidebar w3-bar-block w3-border-right sidemodalpopup"
          style={{ width: "660px", right: "0" }}
          id="contactUsModal"
        >
          <button
            onClick={() => closeContactUsModal()}
            className="w3-bar-item w3-large sidebar-close-btn"
          >
            <img
              className="img-fluid"
              alt="runningman"
              src={imgUrl.close_icon}
              style={{ width: "25px" }}
            />
          </button>
          <div className="sidepopup-content-wrap">
            <div className="content-wrap-sideDiv">
              <h3 className="content-title">CONTACT</h3>
              {/* <h4 className="content-side-title">GENERAL USE</h4> */}
              <p className="sidePopup-content mb-5">
                Have a question? Fill out the form below to get in touch.
              </p>

              <Form className="d-block">
                <Input type="hidden" id="_wpcf7CF" name="_wpcf7" value="600" />
                <Input
                  type="hidden"
                  id="_wpcf7_versionCF"
                  name="_wpcf7_version"
                  value="5.1.7"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_localeCF"
                  name="_wpcf7_locale"
                  value="en_US"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_unit_tagCF"
                  name="_wpcf7_unit_tag"
                  value="wpcf7-f600-o2"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_container_postCF"
                  name="_wpcf7_container_post"
                  value="0"
                />
                <FormGroup className="my-4 w-75">
                  <Label for="your_name1" className="modalFormLabel">
                    FIRST & LAST NAME*
                  </Label>
                  <Input
                    type="text"
                    name="your-name1"
                    id="your_name1"
                    className="side_form_formContol"
                    invalid={yourNameErrCF}
                  />
                  {yourNameErrCF ? (
                    <FormFeedback className="sidebarErrorMessage">
                      The field is required.
                    </FormFeedback>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <FormGroup className="my-4 w-75">
                  <Label for="organization_name1" className="modalFormLabel">
                    ORGANIZATION NAME
                  </Label>
                  <Input
                    type="text"
                    name="organization-name1"
                    id="organization_name1"
                    className="side_form_formContol"
                  />
                </FormGroup>
                <FormGroup className="my-4 w-75">
                  <Label for="organization_type" className="modalFormLabel">
                    ORGANIZATION TYPE
                  </Label>
                  <Input
                    type="select"
                    name="organization-type"
                    id="organization_type"
                    className="side_form_formContol"
                  >
                    <option>Select Organization Type</option>
                    <option>Academia</option>
                    <option>Government</option>
                    <option>Non-Profit</option>
                    <option>Private sector</option>
                    <option>Other</option>
                  </Input>
                </FormGroup>
                <FormGroup className="my-4 w-75">
                  <Label for="your_email1" className="modalFormLabel">
                    EMAIL*
                  </Label>
                  <Input
                    type="email"
                    name="your-email1"
                    id="your_email1"
                    className="side_form_formContol"
                    invalid={yourEmailErrCF}
                  />
                  {yourEmailErrCF ? (
                    <FormFeedback className="sidebarErrorMessage">
                      The field is required.
                    </FormFeedback>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <FormGroup className="my-4 w-75">
                  <Label for="your_message" className="modalFormLabel">
                    YOUR MESSAGE*
                  </Label>
                  <Input
                    type="textarea"
                    name="your-message"
                    id="your_message"
                    className="side_form_formContol"
                    invalid={yourMessageErr}
                  />
                  {yourMessageErr ? (
                    <FormFeedback className="sidebarErrorMessage">
                      The field is required.
                    </FormFeedback>
                  ) : (
                    ""
                  )}
                </FormGroup>

                {/* <p className="overallErrorMessage">
                  One or more fields have an error. Please check and try again.
                </p> */}
                <p className={contactFormMessageClass}>{contactFormMessage}</p>
                <Button
                  type="button"
                  className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase"
                  onClick={submitContactForm}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay hide-modal-popup" id="signUpOverlay">
        <div
          className="w3-sidebar w3-bar-block w3-border-right sidemodalpopup"
          style={{ width: "660px", right: "0" }}
          id="signUpModal"
        >
          <button
            onClick={() => closeSignUpModal()}
            className="w3-bar-item w3-large sidebar-close-btn"
          >
            <img
              className="img-fluid"
              alt="runningman"
              src={imgUrl.close_icon}
              style={{ width: "25px" }}
            />
          </button>
          <div className="sidepopup-content-wrap">
            <div className="content-wrap-sideDiv">
              <h3 className="content-title">SIGN UP FOR OUR NEWSLETTER</h3>
              {/* <h4 className="content-side-title">GENERAL USE</h4> */}
              <p className="sidePopup-content mb-5">
                Sign up for Access Today, our monthly resource of new Access
                Accelerated initiatives, partnership announcements, noteworthy
                events and more.
              </p>

              <Form
                //action="https://accessaccelerated.org/wp-json/contact-form-7/v1/contact-forms/601/feedback"
                id="signUpNewsletter"
                className="d-block"
              >
                <Input type="hidden" id="_wpcf7" name="_wpcf7" value="601" />
                <Input
                  type="hidden"
                  id="_wpcf7_version"
                  name="_wpcf7_version"
                  value="5.1.7"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_locale"
                  name="_wpcf7_locale"
                  value="en_US"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_unit_tag"
                  name="_wpcf7_unit_tag"
                  value="wpcf7-f601-o1"
                />
                <Input
                  type="hidden"
                  id="_wpcf7_container_post"
                  name="_wpcf7_container_post"
                  value="0"
                />
                <FormGroup className="my-4 w-75">
                  <Label for="firstLastName" className="modalFormLabel">
                    FIRST & LAST NAME*
                  </Label>
                  <Input
                    type="text"
                    name="your-name"
                    id="your_name"
                    className="side_form_formContol"
                    invalid={yourNameErr}
                  />
                  {yourNameErr ? (
                    <FormFeedback className="sidebarErrorMessage">
                      The field is required.
                    </FormFeedback>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <FormGroup className="my-4 w-75">
                  <Label for="organizationName" className="modalFormLabel">
                    ORGANIZATION NAME
                  </Label>
                  <Input
                    type="text"
                    name="organization-name"
                    id="organization_name"
                    className="side_form_formContol"
                  />
                </FormGroup>

                <FormGroup className="my-4 w-75">
                  <Label for="email" className="modalFormLabel">
                    EMAIL*
                  </Label>
                  <Input
                    type="email"
                    name="your-email"
                    id="your_email"
                    className="side_form_formContol"
                    invalid={yourEmailErr}
                  />
                  {yourEmailErr ? (
                    <FormFeedback className="sidebarErrorMessage">
                      The field is required.
                    </FormFeedback>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <FormGroup check className="my-4 pl-0 w-75">
                  <Label check className="sidebarLabelCheck">
                    <Input
                      type="checkbox"
                      defaultChecked={signUpConsent}
                      onChange={toggleSignUpConsent}
                    />{" "}
                    I consent accessaccelerated.org to collect and store my data
                    from this form. Your information will be used to send you
                    our news updates. You can change your mind at any tiem by
                    clicking the unsubscribe link at the bottom of any emial
                    that recieved from us. You can find more details about our
                    privacy practices at terms of service section. *
                  </Label>
                </FormGroup>
                <p className={signUpFormMessageClass}>{signUpFormMessage}</p>
                <Button
                  type="button"
                  className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase"
                  onClick={submitNewsletter}
                  disabled={!signUpConsent}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
