const imgUrl = {
    'aalogo_new' : require('./aaop_logo_new.svg'), 
    'aalogo' : require('./aalogo.svg'),
    'runningman' : require('./runningman.jpg'),  
    'aapeople' : require('./aaop_platform_people.png'), 
    'bulb' : require('./light-bulb.svg'),
    'layoutone' : require('./aaop_dash_layout1.png'),  
    'layouttwo' : require('./aaop_dash_layout2.png'),  
    'layoutthree' : require('./aaop_dash_layout3.png'),
    'progone' : require('./prog1.jpg'),
    'progtwo' : require('./prog2.jpg'),
    'progthree' : require('./prog3.jpg'),  
    'supplychain' : require('./supplychain.png'),  
    'supplychain1' : require('./supplychain1.png'),
    'digitalhealth' : require('./digitalhealth.png'),  
    'digitalhealth1' : require('./digitalhealth1.png'),
    'primaryhealth' : require('./primaryhealth.png'),  
    'primaryhealth1' : require('./primaryhealth1.png'), 
    'homeheroimg' : require('./home_hero_aaop.jpg'),  
    'coronamask' : require('./coronamask.jpg'),  
    'blanksvg' : require('./blanksvg.svg'),  
    'ghanaflag' : require('./ghanaflag.png'),  
    'kenyaflag' : require('./kenyaflag.png'),  
    'vietnamflag' : require('./vietnamflag.png'),  
    'downloadarrow' : require('./downloadarrow.svg'),  
    'pp_logo' : require('./pp_logo.svg'),
    'aa_pp_logo' : require('./aa_pp_logo.svg'),
    'bristol_Myers_Squibb' : require('./bristol_Myers_Squibb.png'),
    'aa_in_action' : require('./aa_in_action.png'),
    'ao_badge_small' : require('./ao-badge-small.png'),
    'group_4974' : require('./group_4974.svg'),
    'group_4975' : require('./group_4975.svg'),
    'group_4976' : require('./group_4976.svg'),
    'dureLogo' : require('./dureLogo.png'),
    'ncd_img' : require('./ncd_img.svg'),
    'donut' : require('./donut.svg'),
    'homeheroimg2' : require('./hero_banner_2img.png'), 
    'left_arrow_orange' : require('./left-arrow-orange.svg'), 
    'homeIcon' : require('./homeIcon.svg'),
    'openMagazine' : require('./open-magazine.svg'),
    'pdf_img' : require('./pdf_img.png'),
    'download_img_icon' : require('./download_img_icon.svg'),
    'csv_icon' : require('./csv-file-format-extension.png'),
    'pdf_icon' : require('./pdf-file-format-symbol.png'),
    'left_arrow_white' : require('./left-arrow.png'),
    'feedback_icon' : require('./feedback.svg'),
    'close_icon' : require('./close-icon.svg'),
    'user_white' : require('./user.png'),
}

export default imgUrl;