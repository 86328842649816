import React from "react";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <div id="notfound">
      <Helmet>
        <title>404 - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can't be found</h2>
        </div>
        <a href="/home">Go TO Homepage</a>
      </div>
    </div>
  );
}

export default NotFound;
