import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import imgUrl from "../assets/images/imgUrl.js";

function Login() {
  return (
    <>
      <div className="mastheader-holder loginPage">
        <Container>
          <Row>
            
            <Col lg="12">
              <div className="loginForm pb-5">
                <div className="login-card-title">
                  <h3 className="text-center w-50 mx-auto mb-1">Please login</h3>
                  <h3 className="text-center w-50 mx-auto">to upload data</h3>
                </div>
                <Form className="d-block w-75 mx-auto">
                  <FormGroup className="my-4">
                    {/* <Label for="email" className="modalFormLabel">
                      USERNAME*
                    </Label> */}
                    <Input
                      type="text"
                      name=""
                      id="username"
                      className="side_form_formContol"
                      placeholder="Username"
                    />
                  </FormGroup>
                  <FormGroup className="my-4">
                    {/* <Label for="email" className="modalFormLabel">
                      Password*
                    </Label> */}
                    <Input
                      type="password"
                      name=""
                      id="password"
                      className="side_form_formContol"
                      placeholder="Password"
                    />
                  </FormGroup>
                  <Button className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase btn-block">
                    Login
                  </Button>
                </Form>
              </div>
            </Col>
            <Col sm="6" className="mx-auto">
              <div className="logoImageDiv d-flex">
                <img
                  className="stoptblogoimg mx-auto d-block mb-4"
                  alt="logo"
                  src={imgUrl.aalogo}
                  style={{ width: "145px" }}
                />
                <img
                  className="stoptblogoimg mx-auto d-block mb-4"
                  alt="logo"
                  src={imgUrl.dureLogo}
                  style={{ width: "" }}
                />
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
