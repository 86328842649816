import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import imgUrl from "../assets/images/imgUrl.js";
//import { Link } from "react-router-dom";
import dhi from "../pdf_file/digital_Health_Infographic.pdf";
import sci from "../pdf_file/supply_Chain_Infographic.pdf";
import pri_health_care from "../pdf_file/primary_Health_Care_Infographic.pdf";
import kenyaProfile from "../pdf_file/covid-19_Kenya_June_2021.pdf";
import ghanaProfile from "../pdf_file/covid-19_Ghana_June_2021.pdf";
import vietnamProfile from "../pdf_file/covid-19_VietNam_June_2021.pdf";

function CountryProfiles() {
  return (
    <>
      <Helmet>
        <title>COVID-19 Impact Review - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder bg-darkgrey">
        <Container>
          <Row>
            <Col lg="7">
              <div className="heading">
                <h1 className="partial-underline color-darkblack">
                  COVID-19 Impact Review
                </h1>
              </div>
              <div className="mast-description color-darkblack">
                <p>
                  The COVID-19 Impact Review captures essential information on
                  the impact of COVID-19 on major strategic areas for Access
                  Accelerated and provides detailed profiles for key priority
                  countries and the impact of COVID-19 on the local beneficiary
                  populations.
                </p>
              </div>
            </Col>
            <Col lg="5" className="mastimgholder">
              <div className="mast-imagecontainer">
                <div>
                  <img
                    className="stoptblogoimg"
                    alt="stopTB"
                    src={imgUrl.coronamask}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        id="section1"
        className="dashboardtemplate-holder mb-5 resourceholder"
      >
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="6" md="4">
                <div className="text-center">
                  <div className="portallogodiv1">
                    <div className="">
                      {/* <img
                        className="portallogoimg insideportalLogo"
                        alt="stopTB"
                        src={imgUrl.supplychain1}
                      /> */}
                    </div>
                    {/* <p className="covidportallogotext mb-0">SUPPLY CHAIN</p> */}
                  </div>
                </div>
                <p className="mb-0 mt-2">
                  <a
                    href="https://go.tracelink.com/Discover-How-to-Solve-the-COVID-19-Supply-Chain-Struggle.html?utm_campaign=Content-2020-10-28-IDC-Pharmaceutical-Supply-Chain-Agility-Threat-Assessment&utm_source=Google&utm_medium=paid&utm_content=whitepaper-od&gclid=Cj0KCQiAk53-BRD0ARIsAJuNhptgwEnqhN_zurLEOEppyYgL_bNytfY3Y5AlHOzLWCQh5tdksEDGNp0aAgthEALw_wcB"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 1
                  </a>
                  <a
                    href="https://www.pharmamanufacturing.com/articles/2019/6-pharma-supply-chain-innovations-that-are-worth-the-investment/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 2
                  </a>
                  <a
                    href="https://www.startus-insights.com/innovators-guide/5-top-supply-chain-startups-impacting-pharmaceuticals/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase"
                  >
                    Source 3
                  </a>
                </p>
              </Col>
              <Col lg="6" md="8" className="resourceinfo">
                <div className="text-left">
                  <p className="text-left sectionsubtitle partial-underline supplychainsubtitle">
                    Resources
                  </p>
                  <p className="text-left sectiontitle">Supply Chain</p>
                  <p className="text-left db-description">
                    Resilient supply chain systems one of the stratigic areas
                    for Access Accelerated. The ongoing COVID-19 has caused
                    substantial disruptions in global supply chains leading to
                    delays in delivery of essential medicines and healthcare
                    services. This infographic captures the key challenges that
                    supply chains are facing during COVID-19 and the possible
                    innovations in supply chain manangement that mitigate some
                    of the risks in the future.
                  </p>
                  <p>
                    {/* <Button className="explorealltempbtn btnexploresmall">
                      <span>DOWNLOAD</span>
                    </Button> */}
                    <a
                      href={sci}
                      download="Supply_Chain_Infographic"
                      className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn"
                    >
                      {" "}
                      DOWNLOAD{" "}
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div
        id="section2"
        className="dashboardtemplate-holder mb-5 resourceholder"
      >
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="6" md="8" className="resourceinfo order-xs-2">
                <div className="text-left">
                  <p className="text-left sectionsubtitle partial-underline digitalhealthsubtitle">
                    Resources
                  </p>
                  <p className="text-left sectiontitle">Digital Health</p>
                  <p className="text-left db-description">
                    Digital Health Solutions have the potential to
                    revolutionalise healthcare by providing an improved access
                    to essencial health services in the most remote areas of the
                    world. This infogrpahic presents the key benefits of digital
                    healt and the major classess of digital health solutions
                    that can be utilised in the future to improve health systems
                    management as well as diagnosis and treatment of NCDs.
                  </p>
                  <p>
                    {/* <Button className="explorealltempbtn btnexploresmall">
                    <DownloadLink src="../assets/pdf_files/digital_Health_Infographic.pdf">DOWNLOAD</DownloadLink>
                       <span>DOWNLOAD</span>
                    </Button> */}
                    {/* <a href="../../assets/pdf_files/digital_Health_Infographic.pdf" download>DOWNLOAD</a> */}
                    {/* <Link to="../../public/pdf_files/digital_Health_Infographic.pdf" target="_blank" className="explorealltempbtn btnexploresmall btn btn-secondary" download>DOWNLOAD</Link> */}
                    <a
                      href={dhi}
                      download="Digital_Health_Infographic"
                      className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn"
                    >
                      {" "}
                      DOWNLOAD{" "}
                    </a>
                  </p>
                </div>
              </Col>
              <Col lg="6" md="4" className="order-xs-1">
                <div className="text-center ">
                  <div className="text-center portallogodiv2">
                    {/* <div className=" ">
                      <img
                        className="portallogoimg insideportalLogo"
                        alt="stopTB"
                        src={imgUrl.digitalhealth1}
                      />
                    </div> */}
                    {/* <p className="covidportallogotext mb-0">DIGITAL HEALTH</p> */}
                  </div>
                </div>
                <p className="mb-0 mt-2">
                  <a
                    href="https://www.covingtondigitalhealth.com/2020/03/the-potential-benefits-of-digital-health-technology-in-managing-covid-19/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 1
                  </a>
                  <a
                    href="https://www.fda.gov/medical-devices/digital-health-center-excellence/what-digital-health"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 2
                  </a>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div
        id="section3"
        className="dashboardtemplate-holder mb-5 resourceholder"
      >
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="6" md="4">
                <div className="text-center">
                  <div className="text-center portallogodiv3">
                    {/* <div className=" ">
                      <img
                        className="portallogoimg insideportalLogo"
                        alt="stopTB"
                        src={imgUrl.primaryhealth1}
                      />
                    </div> */}
                    {/* <p className="covidportallogotext mb-0">
                      INTEGRATED PRIMARY HEALTH CARE
                    </p> */}
                  </div>
                </div>
                <p className="mb-0 mt-2">
                  <a
                    href="https://www.who.int/news/item/01-06-2020-covid-19-significantly-impacts-health-services-for-noncommunicable-diseases"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 1
                  </a>
                  <a
                    href="https://www.healthcareitnews.com/blog/emea/forgotten-patients-covid-19-and-impact-people-living-non-communicable-diseases"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase mr-4"
                  >
                    Source 2
                  </a>
                  <a
                    href="https://csis-website-prod.s3.amazonaws.com/s3fs-public/publication/200930_Devermont_Covid19_Elderly_NCD_Care.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sourcelink text-uppercase"
                  >
                    Source 3
                  </a>
                </p>
              </Col>
              <Col lg="6" md="8" className="resourceinfo">
                <div className="text-left">
                  <p className="text-left sectionsubtitle partial-underline primaryhealthsubtitle">
                    Resources
                  </p>
                  <p className="text-left sectiontitle">
                    INTEGRATED PRIMARY HEALTH CARE
                  </p>
                  <p className="text-left db-description">
                    COVID-19 has provided a new lens to view the NCDs. Primary
                    focus of each country has been to contain the pandemic and
                    have put in enormous efforts to deal with it. The
                    infographic sums up the challenges people living with NCDs
                    are facing due to the pandemic.
                  </p>
                  <p>
                    {/* <Button className="explorealltempbtn btnexploresmall">
                      <span>DOWNLOAD</span>
                    </Button> */}
                    <a
                      href={pri_health_care}
                      download="Primary_Health_Care_Infographic"
                      className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn"
                    >
                      {" "}
                      DOWNLOAD{" "}
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div id="more" className="dashboardtemplate-holder mb-5 pt-xs-0">
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="6" md="12" className="resourceinfo">
                <div className="text-left">
                  <p className="text-left sectionsubtitle partial-underline">
                    Profiles
                  </p>
                  <p className="text-left sectiontitle">COUNTRY PROFILES</p>
                  <p className="text-left db-description">
                    The country profiles present a selection of information
                    about the status of COVID-19 in the three Access Accelerated
                    focus countries: Kenya, Ghana and Vietnam. All information
                    represents the status of the country at the date of data
                    collection.
                  </p>
                </div>
              </Col>
              <Col lg="6" md="12">
                <a href={kenyaProfile} download="KenyaProfile" className="">
                  <div className="text-center countryprofiledivcontainer">
                    <Row>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.kenyaflag}
                        />
                      </Col>
                      <Col
                        lg="8"
                        md="8"
                        xs="6"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="mb-0 countryname">Kenya</p>
                      </Col>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.downloadarrow}
                        />
                      </Col>
                    </Row>
                  </div>
                </a>

                <a href={ghanaProfile} download="GhanaProfile" className="">
                  <div className="text-center countryprofiledivcontainer mt-3">
                    <Row>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.ghanaflag}
                        />
                      </Col>
                      <Col
                        lg="8"
                        md="8"
                        xs="6"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="mb-0 countryname">Ghana</p>
                      </Col>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.downloadarrow}
                        />
                      </Col>
                    </Row>
                  </div>
                </a>

                <a href={vietnamProfile} download="VietnamProfile" className="">
                  <div className="text-center countryprofiledivcontainer mt-3">
                    <Row>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.vietnamflag}
                        />
                      </Col>
                      <Col
                        lg="8"
                        md="8"
                        xs="6"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="mb-0 countryname">VIET NAM</p>
                      </Col>
                      <Col lg="2" md="2" xs="3" className="">
                        <img
                          className="portallogoimg"
                          alt="stopTB"
                          src={imgUrl.downloadarrow}
                        />
                      </Col>
                    </Row>
                  </div>
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CountryProfiles;
