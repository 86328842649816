import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Spinner,
} from "reactstrap";
//import Loader from "react-loader-spinner";
import imgUrl from "../../assets/images/imgUrl.js";
import { Parser } from "json2csv";
const FileDownload = require("js-file-download");

const SecondaryNavbarDashboard = (props) => {
  console.log(props);
  //const [spinnerLoading, setSpinnerLoading] = useState(false); // Loader state
  const [exportPDFButtonLabel, setExportPDFButtonLabel] = useState("PDF");
  const [exportCSVButtonLabel, setExportCSVButtonLabel] = useState("CSV");

  const exportFilePDF = () => {
    //setSpinnerLoading(true);
    document
      .getElementById("pdf_download_alert")
      .classList.replace("hide", "show");
    setExportPDFButtonLabel("PDF Export in Progress...");
    let tenantId = "eb42cdeb-b7b5-4a6d-bc1c-d1ebcbfc482e";
    let oauthTokenURL = `https://login.microsoftonline.com/${tenantId}/oauth2/token`;

    let details = {
      grant_type: "password",
      client_id: "004fe472-d42e-40f1-8c91-7e6e3f918535",
      resource: "https://analysis.windows.net/powerbi/api",
      username: "nandkishor@duretech.onmicrosoft.com",
      password: "Azure!8291",
      client_secret: "CUaI67qsynV85Jg2EEW/Nz+g1DnbiWFLwNUK2svdTus=",
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let bearerTokenURL =
      "https://aaopenplatform.accessaccelerated.org/aaop_server/getBearerToken";

    //fetch(oauthTokenURL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    //   body: formBody,
    // })
    fetch(bearerTokenURL, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //sessionStorage.setItem("AccessTokenNew", data.access_token);
        //setAccessToken(data.access_token);
        let accessToken = data;
        let reportID = "83c0064a-b1b1-479d-8216-35b9c76ff7ed";
        let reportURL = `https://api.powerbi.com/v1.0/myorg/reports/${reportID}/pages`;

        //console.log(accessToken);

        fetch(reportURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((dataNew) => {
            //console.log(dataNew);
            var pagesV = dataNew.value;
            //let PageName = sessionStorage.getItem("reportPageName");
            let PageDisplayName = localStorage.getItem("reportPageDisplayName");
            PageDisplayName = PageDisplayName.toLowerCase();
            if (PageDisplayName === "topics") {
              PageDisplayName = "topic";
            }
            if (PageDisplayName === "programs") {
              PageDisplayName = "program";
            }
            if (PageDisplayName === "members") {
              PageDisplayName = "member";
            }

            let pagesFormation = [];

            for (let i = 0; i < pagesV.length; i++) {
              let pNumber = pagesV[i].displayName;
              let pName = pagesV[i].Name;
              pNumber = pNumber.toLowerCase();

              if (pNumber.includes(PageDisplayName)) {
                if (
                  pNumber === "topics" ||
                  pNumber === "countries" ||
                  pNumber === "programs" ||
                  pNumber === "members" ||
                  pNumber === "resources"
                ) {
                } else {
                  let newP = { pageName: pName };
                  pagesFormation.push(newP);
                }
              }
            }
            //console.log(pagesFormation);
            if (accessToken != null) {
              //let dataId = "";
              let exportURL = `https://api.powerbi.com/v1.0/myorg/reports/${reportID}/ExportTo`;

              const exportOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                  format: "PDF",
                  powerBIReportConfiguration: {
                    settings: {
                      locale: "en-US",
                      excludeHiddenPages: "true",
                    },
                    pages: pagesFormation,
                  },
                }),
              };

              fetch(exportURL, exportOptions)
                .then((response) => response.json())
                .then((dataExp) => {
                  //console.log(data);
                  let exportId = dataExp.id;

                  if (exportId !== undefined) {
                    var timer = null;
                    let fileExportURL = `https://api.powerbi.com/v1.0/myorg/reports/83c0064a-b1b1-479d-8216-35b9c76ff7ed/exports/${exportId}/file`;

                    const fileExportOptions = {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                      },
                    };

                    console.time();
                    (async function getStatus() {
                      const res = await fetch(fileExportURL, fileExportOptions);
                      const json = await res.blob();
                      console.log("Start Preparing File : ", json);

                      if (json.size > 8000) {
                        FileDownload(json, PageDisplayName + ".pdf");
                        console.log("File Ready to Download: ", json);
                        console.timeEnd();
                        setExportPDFButtonLabel("PDF");
                        document
                          .getElementById("pdf_download_alert")
                          .classList.replace("show", "hide");
                        clearTimeout(timer);
                        //setSpinnerLoading(false);

                        return true;
                      } else {
                        timer = setTimeout(getStatus, 100000);
                      }
                    })();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  //setSpinnerLoading(false);
                });
            }
          })
          .catch((err) => {
            console.log(err);
            //setSpinnerLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        //setSpinnerLoading(false);
      });
  };

  const exportFileCSV = () => {
    //setSpinnerLoading(true); // Show Loader
    // document
    //   .getElementById("csv_download_alert")
    //   .classList.replace("hide", "show");
    setExportCSVButtonLabel("CSV Export in Progress...");
    fetch("https://aaopenplatform.accessaccelerated.org/aaop_server/getCSV")
      //fetch("http://127.0.0.1:5300/getCSV")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(data);
        //console.log(csv);

        FileDownload(csv, "Programs.csv");
        //setSpinnerLoading(false); // Hide Loader
        setExportCSVButtonLabel("CSV");
        // document
        //   .getElementById("csv_download_alert")
        //   .classList.replace("show", "hide");
      })
      .catch((err) => {
        console.log(`${err} while contacting the API.`);
        //setSpinnerLoading(false); // Hide Loader
        // document
        //   .getElementById("csv_download_alert")
        //   .classList.replace("show", "hide");
      });
    //return true;
  };

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  return (
    <>
      <div className="secondary-navholder">
        <Container>
          <Row>
            <Col className="d-flex justify-content-between">
              <Nav className="justify-content-center">
                <NavLink href="https://accessaccelerated.org/">
                  <img
                    className=""
                    alt=""
                    src={imgUrl.left_arrow_white}
                    style={{ width: "10px", marginRight: "4px" }}
                  />{" "}
                  Back to Access accelerated
                </NavLink>
                <NavLink
                  href="/faqs"
                  className="pr-2 faqs-navLink"
                  onClick={() => setActivePage("faqs-page")}
                >
                  FAQs
                </NavLink>
              </Nav>
              <Nav className="justify-content-end">
                <NavLink
                  // href="https://aaopenplatform.accessaccelerated.org/uploads"
                  href="/login"
                  className="pr-2"
                >
                  Upload Data
                </NavLink>
                <NavLink className="text-dark uploadTab signupbtn downloadbtnicon pr-4">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <div className="">Export Data</div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() => exportFileCSV()}
                        disabled={exportCSVButtonLabel === "CSV" ? false : true}
                      >
                        <img
                          className=""
                          alt=""
                          src={imgUrl.csv_icon}
                          style={{ width: "20px", marginRight: "2%" }}
                        />{" "}
                        {exportCSVButtonLabel}
                      </DropdownItem>
                      {props.reportTab === "Members" ||
                      props.reportTab === "TOPICS" ||
                      props.reportTab === "Countries" ? (
                        <DropdownItem
                          onClick={() => exportFilePDF()}
                          disabled={
                            exportPDFButtonLabel === "PDF" ? false : true
                          }
                        >
                          <img
                            className=""
                            alt=""
                            src={imgUrl.pdf_icon}
                            style={{ width: "20px", marginRight: "2%" }}
                          />{" "}
                          {exportPDFButtonLabel}
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <PowerBIDashboard refs="child"/> */}
                </NavLink>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        id="pdf_download_alert"
        className="pdf_download_alert animate__animated hide"
      >
        <Alert color="dark" style={{ margin: "0px" }}>
          <Spinner color="dark" /> PDF export in progress, file will be
          downloaded when ready...
        </Alert>
      </div>
      {/* <div
        id="csv_download_alert"
        className="csv_download_alert animate__animated hide"
      >
        <Alert color="dark" style={{ margin: "0px" }}>
          <Spinner color="dark" /> CSV export in progress, file will be
          downloaded when ready...
        </Alert>
      </div> */}
      {/* Loader */}
      {/* {spinnerLoading ? (
        <div
          style={{
            textAlign: "center",
            height: "calc(100vh - 6.5rem)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "6.5rem",
          }}
        >
          <Loader
            type="TailSpin"
            color="#f26432"
            height={100}
            width={100}
            visible={spinnerLoading}
          />
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default SecondaryNavbarDashboard;
