import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Table,
  Jumbotron,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import imgUrl from "../assets/images/imgUrl.js";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import Loader from "react-loader-spinner";
import ProgramWorldMap from "../components/ProgramWorldMap.js";
import CalucuumTable from "../components/CalucuumTable.js";
import ProgramStrategy from "../components/ProgramStrategy.js";
import SecondaryNavbar from "../components/navbar/SecondaryNavbarProgramProfile.js";
import AppNavbar from "../components/navbar/AppNavbar.js";
import Footer from "../components/navbar/Footer";
import { Helmet } from "react-helmet";

function ProgramProfile(props) {
  // declaring variables and states
  let filterProgram;
  const [programData, setProgramData] = useState([]); // All Programs Data
  const [regionData, setRegionData] = useState([]); // Region List
  const [countryData, getCountryData] = useState([]); // Region List
  const [ncdData, setNcdData] = useState([]); //NCD list
  const [subNcdData, setSubNcdData] = useState([]); //Sub NCD list
  const [sdgData, setSdgData] = useState([]); //SDG Images
  const [partners, setPartnerData] = useState([]); //Partners Table
  const [ageGroupData, setAgeGroupData] = useState([]); //Key Demographic age group
  const [specialPop, setPopulationData] = useState([]); //Key Demographic population
  const [genderData, setGenderData] = useState([]); //Key Demographic Gender Data
  const [totalBeneficiary, setTotalBeneficiary] = useState(""); // Catchement Population Served Data
  const [cocData, setCocData] = useState([]); // CONTINUUM OF CARE Data
  const [programStrg, setProgramStrg] = useState([]); // CONTINUUM OF CARE Data
  const [catchMentArea, setCatchmentArea] = useState([]); //Catchment Area Data
  const [noDataMSg, setNoDataMsg] = useState(false); // CONTINUUM OF CARE Data
  const [spinnerLoading, setSpinnerLoading] = useState(true); // Loader state

  const programName = useParams();

  // Getting the Program Name from the URL(will update code later)
  let getProgram = `${programName.programName}`; //"Abundant Health";

  //Getting/Filtering Program Details from Program name

  const ProgramDetail = () => {
    fetch(
      "https://aaopenplatform.accessaccelerated.org/aaop_server/getProgramProfiles"
    )
      .then((response) => response.json())
      .then((data) => {
        const Program = data[0].programs.Program;

        filterProgram = Program.filter((prog) => {
          let pathArray = prog.Program_profile_url.split("/");
          return pathArray[4].toLowerCase() === getProgram.toLowerCase();
        });

        if (filterProgram && filterProgram.length > 0) {
          console.log("Pro found!!!");
          setProgramData(filterProgram[0]); //set filtered program data
          setPartnerData(filterProgram[0].partners); // setting List of partners
          setCocData(filterProgram[0].COC);
          setProgramStrg(filterProgram[0].Activities.Activities);

          RegionDetails(filterProgram); //Calling region data
          countryIsoDetails(filterProgram); //Calling country data
          NcdDetails(filterProgram); //calling NCD data
          SdgDetails(filterProgram); //calling SDG data
          Demographics(filterProgram); // calling Demographic Data
          BeneficiaryPop(filterProgram); // Beneficiary Population
          sortCachmentArea(filterProgram); //Catchment Area sorting
          setSpinnerLoading(false); // Hide Loader
        } else {
          console.log("No Data Found");
          setNoDataMsg(true); // Display Msg if No data Found!
          setSpinnerLoading(false); // Hide Loader
        }
      })
      .catch((err) => {
        console.log(`${err} while contacting the quote API.`);
        setNoDataMsg(true); // Display Msg if No data Found!
        setSpinnerLoading(false); // Hide Loader
      });
  };

  const RegionDetails = (filterProgram) => {
    let regionData = filterProgram[0].program_countries;
    if (regionData !== null) {
      let filterRegion = [
        ...new Map(
          regionData.map((item) => [
            JSON.stringify(item.Region_name),
            item.Region_name,
          ])
        ).values(),
      ];
      setRegionData(filterRegion);
    } else {
      setRegionData([]);
    }
  };

  const countryIsoDetails = (filterProgram) => {
    let isoCode;
    let countryData = filterProgram[0].program_countries;

    if (countryData && countryData != null) {
      isoCode = countryData.filter((iso) => {
        return iso.iso3_code;
      });
      let filterCountry = [
        ...new Map(
          isoCode.map((item) => [
            JSON.stringify(item.iso3_code),
            item.iso3_code,
          ])
        ).values(),
      ];
      getCountryData(filterCountry);
    } else {
      getCountryData([]);
    }
  };

  const NcdDetails = (filterProgram) => {
    let ncdData = filterProgram[0].NCDs;
    if (ncdData && ncdData !== null) {
      let filterNcd = [
        ...new Map(
          ncdData.map((item) => [
            JSON.stringify(item["NCDs type"]),
            item["NCDs type"],
          ])
        ).values(),
      ];
      let filterSubNcd = [
        ...new Map(
          ncdData.map((item) => [
            JSON.stringify(item["NCDs sub type"]),
            item["NCDs sub type"],
          ])
        ).values(),
      ];
      setNcdData(filterNcd);
      setSubNcdData(filterSubNcd);
    } else {
      setNcdData([]);
      setSubNcdData([]);
    }
  };

  const SdgDetails = (filterProgram) => {
    let sdgData = filterProgram[0].SDGs;
    if (sdgData && sdgData !== null) {
      let filterSdg = [
        ...new Map(
          sdgData.map((item) => [JSON.stringify(item.sdg_logo), item.sdg_logo])
        ).values(),
      ];
      setSdgData(filterSdg);
    } else {
      setSdgData([]);
    }
  };

  const Demographics = (filterProgram) => {
    let ageGroup, specialPop;
    let genderData = filterProgram[0].beneficiaries_gender;
    let targetPopulation = filterProgram[0].target_populations;

    if (targetPopulation != null) {
      ageGroup = targetPopulation.filter((aGroup) => {
        return aGroup["Population category"] === "Age Group";
      });

      specialPop = targetPopulation.filter((sPop) => {
        return sPop["Population category"] === "Special Populations";
      });

      setAgeGroupData(ageGroup);
      setPopulationData(specialPop);
    } else {
      setAgeGroupData([]);
      setPopulationData([]);
    }

    setGenderData(genderData);
  };

  const BeneficiaryPop = (filterProgram) => {
    if (filterProgram[0].catchement_Population_served !== null) {
      let num =
        filterProgram[0].catchement_Population_served.CatchmentAreas[0]
          .total_pop_served;
      const TotalVal = (num) => {
        if (num > 999 && num < 1000000) {
          return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
          return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
        } else if (num < 900) {
          return num; // if value < 1000, nothing to do
        }
      };

      setTotalBeneficiary(TotalVal(num)); //Pasing Total beneficiary State
    } else {
      setTotalBeneficiary("N/A");
    }
  };

  const sortCachmentArea = (filterProgram) => {
    if (
      filterProgram[0].catchement_Population_served &&
      filterProgram[0].catchement_Population_served.CatchmentAreas[0]
        .Catchment_areas.length > 0
    ) {
      let catchMentData =
        filterProgram[0].catchement_Population_served.CatchmentAreas[0]
          .Catchment_areas;
      let filterCountry = catchMentData.sort(function (a, b) {
        if (a.catchment_province === b.catchment_province) {
          return b.catchement_area - a.catchement_area;
        }
        return a.catchment_province > b.catchment_province ? 1 : -1;
      });
      let finalCachmentData = filterCountry.sort((a, b) =>
        a.catchment_country.localeCompare(b.catchment_country)
      );
      setCatchmentArea(finalCachmentData);
    } else {
      setCatchmentArea([]);
    }
  };

  useEffect(() => {
    ProgramDetail();
    localStorage.setItem("activePage", "program-profile");
  }, []);

  // if (getMerchantRes != null) {
  //   console.log(JSON.stringify(getMerchantRes));
  // }

  // IMPLEMENTING PARTNERS table creation
  const columns = [
    {
      dataField: "Partner_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "partner_type",
      text: "Sector",
      sort: true,
    },
  ];
  const { buttonLabel, className } = props;

  // CONTINUUM OF CARE Modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // PROGRAM STRATEGIES Modal
  const [modalStrategy, setModalStrategy] = useState(false);
  const toggleStrategy = () => setModalStrategy(!modalStrategy);

  return (
    <>
      <AppNavbar />

      {noDataMSg ? (
        // <h2
        //   style={{ maxWidth: "1140px", padding: "100px 10%", color: "#514d4c" }}
        // >
        //   This webpage is not available!
        // </h2>
        <>
          <SecondaryNavbar getProgram={getProgram} exportButton={false} />
          <div id="notfound">
            <Helmet>
              <title>404 - Access Accelerated</title>
              <meta
                name="description"
                content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
              />
              {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
            </Helmet>
            <div className="notfound">
              <div className="notfound-404">
                <h1>Oops!</h1>
                <h2>404 - The Page can't be found</h2>
              </div>
              <a href="/home">Go TO Homepage</a>
            </div>
          </div>
        </>
      ) : (
        <>
          <Helmet>
            <title>{programData.program_name + ` - Access Accelerated`}</title>
            <meta
              name="description"
              content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
            />
            <meta
              name="keywords"
              content={
                programData.program_name +
                `, Programs, Achievements, Reach, Progress, Activities, Research, Patients, Preventions, Centers. Services, Awareness, NCD, Non Communicable Diseases, Education`
              }
            />
            {/* 
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
          </Helmet>
          <SecondaryNavbar getProgram={getProgram} exportButton={true} />
          {spinnerLoading ? (
            <div
              style={{
                textAlign: "center",
                height: "calc(100vh - 6.5rem)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "6.5rem",
              }}
            >
              <Loader
                type="TailSpin"
                color="#f26432"
                height={100}
                width={100}
                visible={spinnerLoading}
              />
            </div>
          ) : (
            <div className="programProfilesPage" id="AA_Program">
              {/* <div>Username: { programName }</div> */}
              <Container fluid={true}>
                <div className="pp_page_nav mt-3">
                  <Row>
                    <Col
                      sm="1"
                      className="d-flex justify-content-center align-items-center"
                      style={{ borderRight: "5px solid #DDDDDD" }}
                    >
                      <img className="firstImg" alt="" src={imgUrl.pp_logo} />
                    </Col>
                    <Col sm="9">
                      <div className="pp_nav_text">
                        <h3>AA PUBLIC HEALTH PROGRAM</h3>
                      </div>
                    </Col>
                    <Col sm="2">
                      <img
                        className="secondImg float-right"
                        alt=""
                        src={imgUrl.aa_pp_logo}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="1"></Col>
                    <Col sm="9">
                      <div className="pp_nav_text">
                        <p>Access Accelerated Open Platform Program Profile</p>
                      </div>
                    </Col>
                    <Col sm="2"></Col>
                  </Row>
                  <div className="border-top-black mt-1 mb-1"></div>
                  <Row>
                    <Col md="4" className="pr-0">
                      <div className="blacksection greySideBlackSection">
                        <div>
                          <p className="updateDateDiv text-white">
                            Last updated from Access Observatory: 09 Sept. 2020
                          </p>
                          <h4 className="titleName text-white">
                            {programData.program_name}
                          </h4>
                          <div className="pp_logoSection">
                            <Row>
                              {programData.Members !== null ? (
                                <>
                                  {programData.Members &&
                                    programData.Members.length > 0 &&
                                    programData.Members.map((member, index) => (
                                      <>
                                        {programData.Members.length == 1 ? (
                                          <Col
                                            sm="6"
                                            className="singleImage mx-auto"
                                          >
                                            <div className="imageDiv_brandLogo">
                                              <img
                                                className="brandLogo"
                                                alt="Brand Logo"
                                                src={member.member_logo}
                                              />
                                            </div>
                                          </Col>
                                        ) : (
                                          <Col sm="6" className="multipleImage">
                                            <div className="imageDiv_brandLogo">
                                              <img
                                                className="brandLogo"
                                                alt="Brand Logo"
                                                src={member.member_logo}
                                              />
                                            </div>
                                          </Col>
                                        )}
                                      </>
                                    ))}{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </Row>
                            {/* <img className="brandLogo" alt="Brand Logo" src={programData.Members ? programData.Members[0].member_logo : ''} /> */}
                            <p className="text-warning text-center text-white">
                              Project end:{" "}
                              {programData.program_end_year
                                ? programData.program_end_year
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="greySection">
                        <div className="regionTextData">
                          <p>
                            <span>Region:</span>
                            {regionData &&
                              regionData.length > 0 &&
                              regionData.map((region, index) => (
                                <>
                                  {regionData.length - 1 == index
                                    ? " " + region
                                    : " " + region + ","}
                                </>
                              ))}
                          </p>
                          <p>
                            <span>NCDs:</span>
                            {ncdData &&
                              ncdData.length > 0 &&
                              ncdData.map((ncd, index) => (
                                <>
                                  {ncdData.length - 1 == index
                                    ? " " + ncd
                                    : " " + ncd + ","}
                                </>
                              ))}
                          </p>
                        </div>
                        <div className="mapSection">
                          {countryData.length > 0 ? (
                            <ProgramWorldMap mapData={countryData} />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="catchment_area_div">
                          <h5 className="contentDiv-header">Catchment areas</h5>
                          <div className="catchment_area_content">
                            <div>
                              <div>
                                {catchMentArea.length > 0 ? (
                                  catchMentArea.map((area) => {
                                    return (
                                      <p>
                                        {area.catchment_country +
                                          " > " +
                                          area.catchment_province +
                                          " > "}
                                        <b>{area.catchement_area}</b>
                                      </p>
                                    );
                                  })
                                ) : (
                                  <p className="no_value_msg">
                                    No values indicated pending information
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-logos-section">
                          <div>
                            <Alert color="dark">
                              <div className="d-flex">
                                <img
                                  className="ao_img"
                                  src={imgUrl.ao_badge_small}
                                />
                                <div className="ao_text">
                                  <p>
                                    The data for this program has been reviewed
                                    by both the companies and the Access
                                    Observatory team.
                                  </p>
                                  <a
                                    href="https://www.accessobservatory.org/"
                                    target="_blank"
                                  >
                                    https://www.accessobservatory.org/
                                  </a>
                                </div>
                              </div>
                            </Alert>
                            <Alert color="" className="mb-0">
                              <div className="d-flex">
                                <img
                                  className="aa_img"
                                  src={imgUrl.aa_in_action}
                                />
                                <div className="aa_text">
                                  <p>
                                    Access Accelerated in Action articulates the
                                    six cross-cutting principles that underpin
                                    the initiative’s commitment to strengthening
                                    NCD programs.{" "}
                                  </p>
                                  <a href="#">Read the full report</a>
                                </div>
                              </div>
                            </Alert>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="8" className="pl-0">
                      <div className="discriptionSection">
                        <div
                          className="blacksection rightBannerSection"
                          style={{
                            backgroundImage: `url(${JSON.stringify(
                              programData.program_logo
                            )})`,
                          }}
                        ></div>
                        <div className="desp-wrap">
                          <div className="collabration-wrap" id="mainCollDesc">
                            <h5>Collaboration Description</h5>
                            <div
                              id="Col_Desc"
                              className="colb-desp-summary colb-desp-summary-height"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: programData.Program_desc,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="contentDiv mt-3">
                            <Row>
                              <Col md="4">
                                <div className="ncd_focus_div">
                                  <h5>NCD Focus</h5>
                                  <div className="ncd_focus_content">
                                    <div className="ncd_img_div">
                                      <img
                                        className="w-100"
                                        src={
                                          programData.active_ncds_url
                                            ? programData.active_ncds_url
                                            : "N/A"
                                        }
                                      />
                                    </div>
                                    <div className="w-100 mt-2 h-75">
                                      {subNcdData && subNcdData.length > 0
                                        ? subNcdData.map((subncd, index) => (
                                            <>
                                              {subNcdData.length - 1 == index
                                                ? " " + subncd
                                                : " " + subncd + ","}
                                            </>
                                          ))
                                        : "N/A"}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="sdgs_div">
                                  <h5>
                                    SDG<span>s</span> Contribution
                                  </h5>
                                  <div className="sdgs_content">
                                    <div className="d-flex">
                                      <div className="sdgs_img_wrap position-relative">
                                        {sdgData && sdgData.length > 0
                                          ? sdgData.map((sdg) => (
                                              <div>
                                                <img
                                                  className="intervation_img"
                                                  src={sdg}
                                                />
                                              </div>
                                            ))
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="key_demo_div">
                                  <h5>Key Demographics</h5>
                                  <div className="key_demo_content">
                                    <div className="h-75 key_tags_wrap mb-2">
                                      {genderData && genderData.length > 0 ? (
                                        genderData.map((gen) => (
                                          <Button
                                            color="secondary"
                                            size="sm"
                                            className="purple_btn"
                                          >
                                            {gen["Beneficiary gender"]}
                                          </Button>
                                        ))
                                      ) : (
                                        <Button
                                          color="secondary"
                                          size="sm"
                                          className="purple_btn"
                                        >
                                          None
                                        </Button>
                                      )}

                                      {specialPop && specialPop.length > 0 ? (
                                        specialPop.map((spclPop) => (
                                          <Button
                                            color="secondary"
                                            size="sm"
                                            className="blue_btn"
                                          >
                                            {spclPop.target_population_desc}
                                          </Button>
                                        ))
                                      ) : (
                                        <Button
                                          color="secondary"
                                          size="sm"
                                          className="blue_btn"
                                        >
                                          None
                                        </Button>
                                      )}

                                      {ageGroupData &&
                                      ageGroupData.length > 0 ? (
                                        ageGroupData.map((ageG) => (
                                          <Button
                                            color="secondary"
                                            size="sm"
                                            className="orange_btn"
                                          >
                                            {ageG.target_population_desc}
                                          </Button>
                                        ))
                                      ) : (
                                        <Button
                                          color="secondary"
                                          size="sm"
                                          className="orange_btn"
                                        >
                                          None
                                        </Button>
                                      )}
                                    </div>
                                    <Button
                                      color="secondary"
                                      size="sm"
                                      className="purple_btn"
                                    >
                                      Gender
                                    </Button>
                                    <Button
                                      color="secondary"
                                      size="sm"
                                      className="blue_btn"
                                    >
                                      Special populations
                                    </Button>
                                    <Button
                                      color="secondary"
                                      size="sm"
                                      className="orange_btn"
                                    >
                                      Age groups
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                <div className="people_count">
                                  <h5 className="contentDiv-header">
                                    People Reached through the Continuum of Care
                                  </h5>
                                  <div className="people_count_content">
                                    {/* <BootstrapTable keyField='id' data={ products_COC } columns={ columnsCOC } /> */}
                                    {programData.COC !== null ? (
                                      <>
                                        <CalucuumTable coc={cocData} />
                                        <a
                                          className="viewClasstext text-right w-100 d-block"
                                          onClick={toggle}
                                        >
                                          View all values
                                        </a>

                                        <Modal
                                          isOpen={modal}
                                          toggle={toggle}
                                          className={className}
                                        >
                                          <ModalHeader toggle={toggle}>
                                            People Reached through the Continuum
                                            of Care
                                          </ModalHeader>
                                          <ModalBody>
                                            <CalucuumTable coc={cocData} />
                                          </ModalBody>
                                          {/* <ModalFooter>
                                            <Button
                                              color="secondary"
                                              onClick={toggle}
                                            >
                                              Cancel
                                            </Button>
                                          </ModalFooter> */}
                                        </Modal>
                                      </>
                                    ) : (
                                      <p className="no_value_msg">
                                        No values indicated pending information
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="8">
                                <div className="program_strat_div">
                                  <h5 className="contentDiv-header">
                                    Program Strategies
                                  </h5>
                                  <div className="program_strat_div program_div_inside">
                                    {programData.Activities !== null ? (
                                      <>
                                        <Table size="sm" borderless>
                                          <tbody>
                                            {programData.Activities
                                              ? programData.Activities
                                                  .Activities.length > 0 &&
                                                programData.Activities.Activities.map(
                                                  (strg) => (
                                                    <tr>
                                                      <td
                                                        style={{ width: "10%" }}
                                                      >
                                                        <img
                                                          src={
                                                            strg.strategy_logo
                                                          }
                                                          style={{
                                                            width: "48px",
                                                          }}
                                                        />
                                                      </td>
                                                      <td
                                                        style={{
                                                          backgroundColor:
                                                            "#F5F5F5",
                                                          padding: "2px 10px",
                                                        }}
                                                      >
                                                        <p>
                                                          {strg.strategy_name}
                                                        </p>
                                                        {strg.activities &&
                                                          strg.activities
                                                            .length > 0 &&
                                                          strg.activities.map(
                                                            (strg_desc) => {
                                                              if (
                                                                strg_desc.Activity_desc !=
                                                                null
                                                              ) {
                                                                return (
                                                                  <Button
                                                                    color="secondary"
                                                                    size="sm"
                                                                    style={{
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                    className="prog_btn"
                                                                  >
                                                                    {
                                                                      strg_desc.Activity_desc
                                                                    }
                                                                  </Button>
                                                                );
                                                              } else {
                                                                return "";
                                                              }
                                                            }
                                                          )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              : ""}
                                          </tbody>
                                        </Table>
                                        <a
                                          className="viewClasstext text-right w-100 d-block"
                                          onClick={toggleStrategy}
                                        >
                                          View all strategies
                                        </a>

                                        <Modal
                                          isOpen={modalStrategy}
                                          toggle={toggleStrategy}
                                          className={className}
                                        >
                                          <ModalHeader
                                            toggle={toggleStrategy}
                                            className="contentDiv-header"
                                          >
                                            Program Strategies
                                          </ModalHeader>
                                          <ModalBody>
                                            <ProgramStrategy
                                              strgData={programStrg}
                                            />
                                          </ModalBody>
                                          {/* <ModalFooter>
                                            <Button
                                              color="secondary"
                                              onClick={toggleStrategy}
                                            >
                                              Cancel
                                            </Button>
                                          </ModalFooter> */}
                                        </Modal>
                                      </>
                                    ) : (
                                      <p className="no_value_msg">
                                        No values indicated pending information
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="beneficiary_area_div">
                                  <h5 className="contentDiv-header">
                                    Beneficiary Population
                                  </h5>
                                  <div className="beneficiary_area_content">
                                    <p className="mb-1">
                                      This program contributes to the{" "}
                                      <span className="orangeText">
                                        Beneficiary Population
                                      </span>
                                      * with:
                                    </p>
                                    <Alert
                                      color="secondary"
                                      className="dataCount"
                                    >
                                      {totalBeneficiary}
                                    </Alert>
                                    <p className="mb-1">
                                      *People living within the catchment area
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                <div
                                  id="Implementing_partner"
                                  className="partners_wrap"
                                >
                                  <h5 className="contentDiv-header">
                                    Implementing Partners
                                  </h5>
                                  <div
                                    className="partners_content"
                                    id="ImplPartner"
                                  >
                                    {partners !== null ? (
                                      <BootstrapTable
                                        keyField="id"
                                        data={partners}
                                        columns={columns}
                                      />
                                    ) : (
                                      <p className="no_value_msg">
                                        No values indicated pending information
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <footer>
                  <Row>
                    <Col>
                      <div className="float-right">
                        <div className="footerDivWrap">
                          <h5>AA Open Platform 2021 -</h5>
                          <img className="" src={imgUrl.dureLogo} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </footer>
              </Container>
            </div>
          )}
        </>
      )}
      <Footer />
    </>
  );
}

export default ProgramProfile;
