import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import imgUrl from "../assets/images/imgUrl.js";

function FaqsPage() {
  return (
    <>
      <Helmet>
        <title>FAQs - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder faqsPage masterWrap">
        <Container>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8">
              <div className="heading">
                <h1 className="faqs-title">
                  <span className="orangeword">F</span>requently{" "}
                  <span className="orangeword">A</span>sked{" "}
                  <span className="orangeword">Q</span>uestions
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="faqs-quest-section pb-5">
        <Container>
          <Row>
            <Col lg="2" md="2" sm="2"></Col>
            <Col lg="10" md="10" sm="10">
              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  GENERAL INFORMATION
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What is the Access Accelerated Open Platform?
                  </h3>
                  <p className="ans-text">
                    The Access Accelerated Open Platform (AAOP) is a knowledge
                    resource and dashboard that provides a centralized
                    information repository of action on non-communicable
                    diseases (NCDs). It is designed to inform users about NCD
                    programs, catalyze biopharmaceutical industry NCD action in
                    low- and middle-income countries (LMICs) and guide members
                    and partners to adapt their work amid the COVID-19 pandemic.{" "}
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    When was the Access Accelerated Open Platform first
                    launched?{" "}
                  </h3>
                  <p className="ans-text">
                    The AA Open Platform (AAOP) was first launch in December
                    2018 and received a major upgrade in June 2021 and is
                    continuing to demonstrate the industry’s commitment to
                    accountability and measurement.{" "}
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What are the core competencies of the platform?{" "}
                  </h3>
                  <p className="ans-text">
                    The core competencies of the AAOP include :
                    <ul>
                      <li>
                        <span className="ans-text-list">
                          Normalizing of data from various sources
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Interactive charts that dynamically change the
                          dashboard with a single-click
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Easy to use data upload and download functions
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Straight forward information search and advanced
                          filters
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          A Centralized Information Repository{" "}
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          A focus on measurable indicators at different
                          geographical levels{" "}
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Enhanced analytical capabilities of program and open
                          data
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What are the focus areas of Access Accelerated Open Platform
                    keeping in mind the evolving needs of its users?
                  </h3>
                  <p className="ans-text">
                    The Open Platform focuses on monitoring status and
                    progression of the biopharmaceutical industries’ programs
                    addressing NCD-burden through chronic conditions like heart
                    diseases, respiratory diseases, cancer, diabetes and other
                    NCDs, that are collectively responsible for almost 70% of
                    all deaths worldwide. The programs and a visual analysis can
                    be viewed in our{" "}
                    <a className="ans-text" href="/home">
                      NCD dashboard
                    </a>
                    .
                  </p>
                  <p className="ans-text">
                    In our{" "}
                    <a className="ans-text" href="/covid-19-portal">
                      COVID-19 Portal
                    </a>
                    , we host information and responses related to the COVID-19
                    pandemic and present several analytical tools that provide
                    strategic information to members and partners that will help
                    modify their activities addressing the NCD burden.
                  </p>
                </div>
              </div>

              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  AUDIENCE AND APPLICATION
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">Who can use the Open Platform?</h3>
                  <p className="ans-text">
                    Everyone interested in learning about the current efforts to
                    reduce the NCD burden by the biopharmaceutical industry,
                    government bodies, NGOs, civil society, and people living
                    with NCDs. As of today, the Open Platform has a growing
                    network from over 100 different countries.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What will I find on the Open Platform?
                  </h3>
                  <p className="ans-text">
                    The Open Platform provides visualizations and analysis of
                    the newest NCD-program information, peer-reviewed data and
                    unique insights into the public and biopharmaceutical
                    industries alike.{" "}
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How does the Open Platform benefit its users?{" "}
                  </h3>
                  <p className="ans-text">
                    The Open Platform allows users to compare existing program
                    initiatives and identify gaps, synergies and opportunities
                    for action and avoid program fragmentation. With
                    easy-to-understand interactive visualizations, the Open
                    Platform supports capacity building, communications,
                    advocacy, monitoring and evaluation.{" "}
                  </p>
                </div>
              </div>

              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  JUNE 2021 UPGRADE
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What are some of the new features added to the Open
                    Platform?{" "}
                  </h3>
                  <p className="ans-text">
                    Users can search, retrieve, and report data on global NCD
                    action more efficiently. Additionally, through the new
                    COVID-19 Portal update, users can get insights on how to
                    adapt programs to meet the needs of people living with NCDs.
                  </p>
                  <p className="ans-text">
                    Highlights include:
                    <ul>
                      <li>
                        <span className="ans-text-list">
                          Easy to use data upload and download functions
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Improved information search and advanced filters
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Interactive charts that dynamically change the
                          dashboard with a single-click
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Automated data processing and management in the
                          Centralized Information Repository
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Key technology upgrades enabling enhanced analytical
                          capabilities, a focus on measurable indicators at
                          different geographical levels and to provide
                          customizable analytical portals
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How has the Open Platform evolved in response to COVID-19
                    pandemic?
                  </h3>
                  <p className="ans-text">
                    In 2020, COVID-19 disrupted the status quo, impacting lives
                    and economies alike. The new{" "}
                    <a className="ans-text" href="/covid-19-portal">
                      COVID-19 Portal
                    </a>{" "}
                    presents several analytical tools for providing strategic
                    information to members and partners to facilitate the
                    adaptation of their work to address the NCD-burden of LMICs
                    considering the ongoing COVID-19 pandemic.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What benefits does the dashboard upgrade provide its users?
                  </h3>
                  <p className="ans-text">
                    Users can more easily search, retrieve, and report data on
                    global NCD activity. Users can also learn how to tailor
                    programs to fit the needs of people living with NCDs through
                    the new COVID-19 Portal update.
                  </p>
                </div>
              </div>

              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  DATA MAINTENANCE AND SOURCES
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What are the sources of the Open Platform?{" "}
                  </h3>

                  <p className="ans-text">
                    The Open Platform receives information from two main sources
                    which work with together with Access Accelerated:
                    <ul>
                      <li>
                        <span className="ans-text-list">
                          The{" "}
                          <a
                            className="ans-text"
                            href="https://globalhealthprogress.org/"
                          >
                            Global Health Progress (GHP)
                          </a>{" "}
                          provides self-published information from the
                          individual companies of the biopharmaceutical
                          industry. This data is the most recent information,
                          directly updated on the GHP, which is not third-party
                          verified.
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          <a
                            className="ans-text"
                            href="https://www.accessobservatory.org/"
                          >
                            Access Observatory (AO)
                          </a>{" "}
                          provides standardized information and data that has
                          been collected and reviewed throughout each ongoing
                          year by an independent partner (Boston University,
                          2017-2021){" "}
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Several established open data sources are referenced
                        </span>
                      </li>
                    </ul>
                    Data on the COVID-19 portal
                    <ul>
                      <li>
                        <span className="ans-text-list">
                          The COVID-19 Portal uses various open data sources
                          that are individually indicated next to the relevant
                          charts.
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How often is data collected and updated on the platform?
                  </h3>

                  <p className="ans-text">
                    The program database is regularly synchronized with multiple
                    data sources based on availability or updates received
                    including those from Access Observatory (annual update),
                    Global Health Progress (monthly update), Access Accelerated
                    partners and open data sources.
                  </p>
                </div>
              </div>

              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  ACCESS AND FUNCTIONS
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    Where can I find the Open Platform?{" "}
                  </h3>

                  <p className="ans-text">
                    The Open Platform can be found on the Access Accelerated
                    homepage and is part of the OUR WORK segment.
                  </p>
                  <a href="https://accessaccelerated.org/" className="ans-text">
                    Explore the Access Accelerated home page
                  </a>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How can I start the dashboard?{" "}
                  </h3>

                  <p className="ans-text">
                    To start the dashboard, the user needs to click on the{" "}
                    <a className="ans-text" href="/home">
                      NCD Dashboard
                    </a>{" "}
                    on the homepage of the portal for viewing the data related
                    to NCDs for various members and partners across all WHO
                    regions.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    Can the data from the platform be downloaded?{" "}
                  </h3>

                  <p className="ans-text">
                    Yes, the data of every chart can be exported and used via
                    the options icon (top right corner of each chart). All
                    profiles (member, NCDs, country) will be available to export
                    as a PDF. An option to share the visualized information via
                    social media will be added.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    Can I download important documents like NCDs policies,
                    country profiles from the platform?{" "}
                  </h3>

                  <p className="ans-text">
                    Yes, the records of each chart including NCDs policies,
                    country profiles can be exported via the options icon (top
                    right corner of each chart) and will be available to export
                    as a PDF. An option to share the visualized information via
                    social media will be added.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    Where can I find the partners, members, and country
                    profiles?{" "}
                  </h3>

                  <p className="ans-text">
                    The data can be dynamically published as partners, members
                    and country profiles. They are available under the{" "}
                    <a className="ans-text" href="/home">
                      NCD Dashboard
                    </a>{" "}
                    on the home page of the Open Platform. The data for all AA
                    members and partners across all WHO regions is available
                    there.
                  </p>
                </div>
              </div>

              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  THE FUTURE OF THE OPEN PLATFORM
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How will the Open Platform keep improving?{" "}
                  </h3>

                  <p className="ans-text">
                    The Open Platform continues to collect user feedback to
                    improve data maintenance and transparency and to align with
                    Access Accelerated’s evolving strategies. The newest version
                    has been visually and functionally updated with a more
                    robust and sustainable approach to data collection,
                    visualization and self-use analysis.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What is the roadmap for the platform?{" "}
                  </h3>

                  <p className="ans-text">
                    Additional tools and modules are being planned, including
                    targeted focus on COVID-19 mitigation linked to vaccine
                    introduction, easier information search aligned to the
                    strategic areas of Access Accelerated (supply chain, digital
                    health, and integrated primary healthcare) and continuous
                    focus on measurable indicators across programs and
                    countries. Further activities will be planned based on
                    ongoing feedback.
                  </p>
                  <a href="/home" className="ans-text">
                    Click here to explore the updated Access Accelerated Open
                    Platform.
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="feedbackIconDiv">
        <a
          href="https://docs.google.com/forms/d/199H0jtinEp8764lwJ8s0EM5liX64nNmr4FgSL-Hoq_c/viewform?edit_requested=true"
          target="_blank"
        >
          <img
            className="img-fluid"
            alt="runningman"
            src={imgUrl.feedback_icon}
            style={{ width: "90%" }}
          />
        </a>
      </div>
    </>
  );
}

export default FaqsPage;
