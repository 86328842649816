import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Container,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText,
} from "reactstrap";

export default function FileUpload(type) {
    const selectedVal =  type.type;
    const [selectedFile, setSelectedFile] = useState(); // Set select file
    const [file, getFile] = useState(false); // Set select file
    const [uploadedFile,setUploadedFile] = useState({}); // Set uploaded files
    const [uploadError, setUploadError] = useState(''); // set upload Error
    const [allPrograms, getAllPrograms] = useState([]); // Get All Programs
    const [allMembers, getAllMembers] = useState([]); // Get All AAMembers
    const [allNCD, getAllNCD] = useState([]); // Get All AAMembers
    const [categorySelect, setCategory] = useState('');
    console.log("selectedVal",selectedVal)


    const changeHandler = (event) => { // For Upload Input value
        if(event.target.files[0] !== undefined) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const categorySelection = (event) => {
        let name = event.target.name;
        if(event.target.value !== undefined) {
            if(name === "program") {
                setCategory(event.target.value);
            } else if(name === "member") {
                setCategory(event.target.value);
            } else if(name === "ncd") {
                setCategory(event.target.value);
            } else {
                console.log('event.target.name',event.target.name);
                setCategory('');
            }
        }
    }

    const handleSubmission = () => { // Post Data to the server
        if(selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            const selectedFileName = selectedFile.name;
            getFile(false);
            fileValidation(selectedVal, selectedFileName, formData);               
        } else {
            getFile(true);
            console.log("Please select file to upload");
        }
    };

    const UploadFile = async (formData) => {
        console.log("upload func")
        try {
            const res = await axios.post('http://localhost:3001/upload',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const { fileName, filePath } = res.data;
            setUploadedFile({ fileName, filePath });
        } catch(err) {
            if(err.response.status === 500) {
                console.log('There was problem with the server');
            } else {
                console.log(err.response.data.msg);
            }
        }
    }

    const fileValidation = (selectedVal, selectedFileName, formData) => {
        const imgArr = ['Program banner logo', 'Member logo', 'NCD logo'];
        const filesArr = ['GHP export data', 'Covid daily data', 'Covid stringency Data', 'Covid response category data']
        if(filesArr.includes(selectedVal)) {
            // Allowing file type
            const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i
            console.log("File upload selected",allowedExtensions);
            if (!allowedExtensions.exec(selectedFileName)) {
                setSelectedFile();
                setUploadedFile({});
                setUploadError('file');
                // fileInput.value = '';
                // return false;
            } else {
                console.log("file upload function start");
                setUploadError('');
                UploadFile(formData);
            }
        } else if (imgArr.includes(selectedVal)) {
            // Allowing file type
            const allowedExtensions = /(\.png)$/i
            console.log("Image upload selected", allowedExtensions);

            if (!allowedExtensions.exec(selectedFileName)) {
                // document.querySelectorAll('input')
                setSelectedFile();
                setUploadedFile({});
                setUploadError('image');
                // fileInput.value = '';
                // return false;
            } else {
                console.log("file upload function start")
                setUploadError('');
                UploadFile(formData);
            }
        } else {
            setUploadError("Uploaded file is not Valid");
        }

    };

    const ProgramNamesCall = () => {
        fetch(
            "https://aaopenplatform.accessaccelerated.org/aaop_server/getProgramProfiles"
          )
        .then((response) => response.json())
        .then((data) => {
            const Program = data[0].programs.Program;
            getAllPrograms(Program);
        })
        .catch((err) => {
            console.log(`${err} while contacting the quote API.`);
        });
    }

    const AAMemberCall = () => {
        fetch(
            "http://localhost:3001/getMembers"
          )
        .then((response) => response.json())
        .then((data) => {
            getAllMembers(data);
        })
        .catch((err) => {
            console.log(`${err} while contacting the quote API.`);
        });
    }

    const NcdCall = () => {
        fetch(
            "http://localhost:3001/getNcdList"
          )
        .then((response) => response.json())
        .then((data) => {
            getAllNCD(data);
        })
        .catch((err) => {
            console.log(`${err} while contacting the quote API.`);
        });
    }

    useEffect(() => {
        ProgramNamesCall();
        AAMemberCall();
        NcdCall();
    }, []);

    return (
        <>
        {selectedVal && selectedVal === "Program banner logo" ?
        (<Row className="mt-5 mb-3">
            <Col sm="6">
                <FormGroup>
                <Label for="exampleSelect">Select Program</Label>
                    <Input type="select" name="program" id="programSelect" onChange={categorySelection}>
                        <option value="N/A">N/A</option>
                        { allPrograms && allPrograms.length>0 && allPrograms.map((prog)=>
                        <option value={prog.Program_id} key={prog.Program_id}>{prog.program_name}</option> 
                        )}
                    </Input>
                </FormGroup>
            </Col>
        </Row>) : '' }
        {selectedVal && selectedVal === "Member logo" ?
        (<Row className="mt-5 mb-3">
            <Col sm="6">
                <FormGroup>
                <Label for="exampleSelect">Select Member</Label>
                    <Input type="select" name="member" id="memberSelect" onChange={categorySelection}>
                        <option value="N/A">N/A</option>
                        { allMembers && allMembers.length>0 && allMembers.map((member)=>
                        <option value={member.org_displayed_name} key={member.org_displayed_name}>{member.org_displayed_name}</option> 
                        )}
                    </Input>
                </FormGroup>
            </Col>
        </Row>) : '' }
        {selectedVal && selectedVal === "NCD logo" ?
        (<Row className="mt-5 mb-3">
            <Col sm="6">
                <FormGroup>
                <Label for="exampleSelect">Select NCD</Label>
                    <Input type="select" name="ncd" id="ncdSelect" onChange={categorySelection}>
                        <option value="N/A">N/A</option>
                        { allNCD && allNCD.length>0 && allNCD.map((ncd)=>
                        <option value={ncd.ncd_type} key={ncd.ncd_type}>{ncd.ncd_type}</option> 
                        )}
                    </Input>
                </FormGroup>
            </Col>
        </Row>) : '' }
        <Row className="mt-5 mb-3">
            <Col sm="4">
                <FormGroup>
                    {selectedVal && selectedVal !== "N/A" ? 
                    <Input type="file" name="file" id="exampleFile" onChange={changeHandler} />
                    : <Input type="file" name="file" id="exampleFile" disabled onChange={changeHandler} />
                    }
                    <>
                    {uploadedFile ? (
                    <div>
                        <p style={{fontSize: '12px', margin: '10px'}}>{uploadedFile.fileName ? <> '{uploadedFile.fileName}' <b>uploaded!</b></> : '' }</p>
                    </div>
                    ) : (
                        <p style={{fontSize: '12px', margin: '10px',  color: '#f00'}}>Something went wrong! Sorry</p>
                    )}
                    </>
                    <>
                    {uploadError === 'image' ? (
                        <p style={{fontSize: '12px', margin: '10px',  color: '#f00'}}>Only '.png' file allowed</p>
                    ) : [
                        (uploadError === 'file'
                          ? <p style={{fontSize: '12px', margin: '10px', color: '#f00'}}>Only '.csv, .xls, .xlsx' file allowed</p>
                          : ''
                        ),
                        ''
                      ]
                    }
                    </>
                    <>
                    {file ?
                        (<p style={{fontSize: '12px', margin: '10px',  color: '#f00'}}>Please select file to upload!</p>) : ''
                    }
                    </>
                </FormGroup>
            </Col>
            <Col sm="2">
                <FormGroup>

                    <Button color="secondary" onClick={handleSubmission}>Upload</Button>{' '}

                </FormGroup>
            </Col>
        </Row>
        </>
    )
}