import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Table,
  Jumbotron,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import imgUrl from "../assets/images/imgUrl.js";
import { render } from "react-dom";
// Import Highcharts
import $ from "jquery";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
//import mapDataAsia from './mapDataAsia'
import mapdata from "./mapdata.js";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";

//import makeData from './makeData'

function ProgramProfiles(props) {
  let mapdatanew = mapdata;
  console.log(mapdatanew);

  let getMerchantRes = localStorage.getItem("dbMerchantData");
  let GetMrcntData = localStorage.getItem("dbMerchantDataNew");
  let Pro_Data = localStorage.getItem("Program_Data");
  let Program = JSON.parse(Pro_Data);

  let p_Count = Program.length;
  let p_id = 0;
  let COC_program = [];

  // COC_Program:
  // Abundant Health
  // Access and Affordability Initiative (AAI)
  // Changing Diabetes inChildren
  // Mother to Mother Project
  for (let i = 0; i < p_Count; i++) {
    let p_Name = Program[i].program_name;
    if (Program[i].COC != null) {
      COC_program.push(i); //59
    }
    if (p_Name == "Changing Diabetes inChildren") {
      //Secure The Future - KwaZulu Natal South Africa
      p_id = i;
    }
  }

  //const newdata=JSON.parse(GetMrcntData);
  let p_Logo = Program[p_id].program_logo;
  console.log(Program[p_id]);
  sessionStorage.setItem("dataDB", JSON.stringify(Program[p_id]));

  let program_logo = JSON.stringify(p_Logo);
  let titleName = Program[p_id].program_name;
  let program_end_year = Program[p_id].program_end_year;
  let member_logo = Program[p_id].member_logo;

  let Region_name = Program[p_id].program_countries[0].Region_name;
  let NCD = getNCD();
  let Program_desc = Program[p_id].Program_desc;

  let active_ncds_url = Program[p_id].active_ncds_url;
  let beneficiaries_gender = "N/A";
  if (Program[p_id].beneficiaries_gender != null)
    beneficiaries_gender =
      Program[p_id].beneficiaries_gender[0]["Beneficiary gender"];

  let target_populations = [];
  if (Program[p_id].target_populations != null) {
    for (let i = 0; i < Program[p_id].target_populations.length; i++) {
      target_populations.push(
        Program[p_id].target_populations[i]["target_population_desc"]
      );
      target_populations.push(
        Program[p_id].target_populations[i]["Population category"]
      );
    }
  }

  let catchment_area = getCatchment();
  let catchment_Country = "N/A";
  if (Program[p_id].catchement_Population_served != null)
    catchment_Country =
      Program[p_id].catchement_Population_served[0].catchment_country;

  function getNCD() {
    let NCD_A = "N/A";
    if (Program[p_id].NCDs != null) {
      NCD_A = Program[p_id].NCDs[0]["NCDs type"];
      for (let i = 0; i < Program[p_id].NCDs.length; i++) {
        NCD_A += "," + Program[p_id].NCDs[i]["NCDs sub type"];
      }
    }

    return NCD_A;
  }
  function getCatchment() {
    let Catchment_A;
    let cnt = 0;
    if (Program[p_id].catchement_Population_served != null) {
      for (
        let i = 0;
        i < Program[p_id].catchement_Population_served.length;
        i++
      ) {
        if (cnt == 0) {
          Catchment_A =
            Program[p_id].catchement_Population_served[i].catchement_area +
            "<br/>";
          cnt = 1;
        } else {
          Catchment_A +=
            Program[p_id].catchement_Population_served[i].catchement_area +
            "<br/>";
        }
      }
    } else {
      Catchment_A = "N/A";
    }

    return Catchment_A;
  }
  //Abundant Health
  // CoC_category: "Screening"
  // CoC_desciption: "Screening"
  // population_reached: 39782
  // reporting_year: "2019"
  //COC
  // sdg_logo: "https://aaopenplatform.accessaccelerated.org/images/SDG_images/SDG 3-Good health and well-being.png"
  // sdg_name:

  function periodFormatter(cell, row) {
    if (row) {
      return (
        <span>
          {/* <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong> */}
        </span>
      );
    }

    return <span> {cell}</span>;
  }

  let Country = "N/A";
  let CountryMap = "N/A";
  let newMapdata = "N/A";
  if (catchment_Country != "N/A") {
    for (let i = 0; i < mapdatanew.features.length; i++) {
      if (catchment_Country == mapdatanew.features[i].properties.name) {
        Country = mapdatanew.features[i].id;
        CountryMap = mapdatanew.features[i];
        newMapdata = mapdatanew.features[i].properties.continent;
      }
    }
  }

  //let SDG_Logo= getSDGLogo();
  let SDG_A = [];
  if (Program[p_id].SDGs != null) {
    for (let j = 0; j < Program[p_id].SDGs.length; j++) {
      //  let img = document.createElement('img');
      //   img.src = Program[p_id].SDGs[j]["sdg_logo"];
      //   img.style.width='82';
      SDG_A.push(Program[p_id].SDGs[j]["sdg_logo"]);
    }
  } else {
    SDG_A.push("N/A");
  }

  function priceFormatter(cell, row) {
    // if (row.onSale) {
    //   return (
    //     <span>
    //       <img style={ { width: "82px" } } src={ cell }></img>
    //     </span>
    //   );
    // }

    return (
      <span>
        {" "}
        <img style={{ width: "28px" }} src={cell}></img>
      </span>
    );
  }
  let StrategiesArray = null;
  if (Program[p_id].Activities != null) {
    StrategiesArray = Program[p_id].Activities.Activities;
  }
  // CoC_category: "Treatment"
  // CoC_desciption: "Treatment"
  // population_reached: 6023
  // reporting_year: "2017"

  let COC_Cate = Program[p_id].COC;
  let Awareness = [4];
  Awareness[0] = "";
  let Screening = [4];
  Screening[0] = "";
  let Diagnosis = [4];
  Diagnosis[0] = "";
  let Treatment = [4];
  Treatment[0] = "";
  let LongTerm = [4];
  LongTerm[0] = "";
  let Health = [4];
  Health[0] = "";
  let Others = [4];
  Others[0] = "";
  if (COC_Cate != null) {
    if (COC_Cate.length > 0) {
      for (let i = 0; i < COC_Cate.length; i++) {
        if (COC_Cate[i].CoC_category == "Awareness") {
          if (COC_Cate[i].reporting_year == "2017")
            Awareness[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Awareness[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Awareness[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Awareness[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "Screening") {
          if (COC_Cate[i].reporting_year == "2017")
            Screening[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Screening[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Screening[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Screening[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "Diagnosis") {
          if (COC_Cate[i].reporting_year == "2017")
            Diagnosis[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Diagnosis[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Diagnosis[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Diagnosis[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "Treatment") {
          if (COC_Cate[i].reporting_year == "2017")
            Treatment[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Treatment[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Treatment[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Treatment[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "LongTerm") {
          if (COC_Cate[i].reporting_year == "2017")
            LongTerm[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            LongTerm[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            LongTerm[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            LongTerm[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "Health system strenthening") {
          if (COC_Cate[i].reporting_year == "2017")
            Health[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Health[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Health[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Health[3] = COC_Cate[i].population_reached;
        } else if (COC_Cate[i].CoC_category == "Other") {
          if (COC_Cate[i].reporting_year == "2017")
            Others[0] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2018")
            Others[1] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2019")
            Others[2] = COC_Cate[i].population_reached;
          else if (COC_Cate[i].reporting_year == "2020")
            Others[3] = COC_Cate[i].population_reached;
        }
      }
    }
    // else{

    // }
  }
  // else
  // {

  // }

  let total_population_served1 = "N/A";
  if (Program[p_id].catchement_Population_served != null)
    total_population_served1 =
      Program[p_id].catchement_Population_served[0].total_population_served;
  //let total_population_served2= Program[p_id].catchement_Population_served[1].total_population_served;
  let FormattedPopulation = "N/A";
  if (total_population_served1 != "N/A") {
    FormattedPopulation = numFormatter(total_population_served1);
  }

  function numFormatter(total_population_served1) {
    if (total_population_served1 > 999 && total_population_served1 < 1000000) {
      return (total_population_served1 / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (total_population_served1 > 1000000) {
      return (total_population_served1 / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (total_population_served1 < 1000) {
      return total_population_served1; // if value < 1000, nothing to do
    }
  }

  //var data =[['za',2]];
  var chartData = [];
  var point = [];

  let v1 = "";
  let v2 = "";
  let CouProNam = "";
  if (CountryMap != "N/A") {
    v1 = CountryMap.properties["hc-key"];
    v2 = CountryMap.properties["labelrank"];
    CouProNam = CountryMap.properties.name;
  }
  point.push(v1);
  point.push(v2);
  chartData.push(point);

  // Load Highcharts modules
  require("highcharts/modules/map")(Highcharts);
  // Highcharts.mapChart('container', {

  // });

  const mapOptions = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        color: "#fd7e14",
        mapData: mapdata,
        name: CouProNam,
        data: chartData,

        //         dataLabels: {
        //           enabled: true,
        //           format: catchement_area1,catchement_area2
        //         }
      },
    ],
  };

  // const mapOptions2 = {
  //   title: {
  //     text: ''
  //   },
  //   credits: {
  //     enabled: false
  //   },
  //   series: [
  //     {
  //       color:'#fd7e14',
  //       mapData:mapdata,
  //       name: CountryMap.properties.name,
  //       data:chartData
  //       // ,
  //       //         dataLabels: {
  //       //           enabled: true,
  //       //           format: catchement_area1,catchement_area2
  //       //         }
  //     }
  //   ]
  // };

  if (getMerchantRes != null) {
    console.log(JSON.stringify(getMerchantRes));
  }

  const columns = [
    {
      dataField: "Partner_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "partner_type",
      text: "Sector",
      sort: true,
    },
  ];

  const products = Program[p_id].partners;

  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className="programProfilesPage">
      <Container fluid={true}>
        <div className="pp_page_nav mt-3">
          <Row>
            <Col sm="1" style={{ borderRight: "5px solid #DDDDDD" }}>
              <img className="firstImg" alt="" src={imgUrl.pp_logo} />
            </Col>
            <Col sm="9">
              <div className="pp_nav_text">
                <h3>AA PUBLIC HEALTH PROGRAM</h3>
              </div>
            </Col>
            <Col sm="2">
              <img className="secondImg" alt="" src={imgUrl.aa_pp_logo} />
            </Col>
          </Row>
          <Row>
            <Col sm="1"></Col>
            <Col sm="9">
              <div className="pp_nav_text">
                <p>Access Accelerated Open Platform Program Profile</p>
              </div>
            </Col>
            <Col sm="2"></Col>
          </Row>
          <div className="border-top-black mt-1 mb-1"></div>
          <Row>
            <Col md="4" className="pr-0">
              <div className="blacksection">
                <div>
                  <p className="updateDateDiv text-white">
                    Last updated from Access Observatory: 09 Sept. 2020
                  </p>
                  <h4 className="titleName text-white">
                    {/* Secure The Future - KwaZulu Natal South Africa */}
                    {titleName}
                  </h4>
                  <div className="pp_logoSection">
                    <img
                      className="brandLogo"
                      alt="Brand Logo"
                      src={imgUrl.bristol_Myers_Squibb}
                    />
                    <p class="text-warning text-center text-white">
                      Project end: {program_end_year}
                    </p>
                  </div>
                </div>
              </div>
              <div className="greySection">
                <div className="regionTextData">
                  <p>
                    <span>Region:</span> {Region_name}
                  </p>
                  {/* African Region */}
                  <p>
                    <span>NCDs:</span> {NCD}
                  </p>
                  {/* Cancer */}
                  <p>
                    <span>Focus:</span>N/A
                  </p>
                  {/* {NCDs1},{NCDs2},{NCDs3} */}
                  {/* {active_ncds_url} */}
                  {/* Integrated Primary Health Care */}
                </div>
                <div className="mapSection">
                  {/* <div id="world_map" /> */}
                  <HighchartsReact
                    options={mapOptions}
                    constructorType={"mapChart"}
                    highcharts={Highcharts}
                  />
                </div>
                <div className="objectiveDataDiv">
                  <h5>Our Objective</h5>
                  <p>
                    Raise awareness, identify barriers to access diagnosis,
                    treatment and palliative care by lung cancer patients; and
                    strengthen infrastructure and clinical interventions for
                    lung cancer care in communities that less focus on the
                    disease.
                  </p>
                </div>
                <div className="milestonesDiv">
                  <h5>Milestones</h5>
                  <ul>
                    <li>
                      <p>
                        In five communities, households were educated on lung
                        cancer and information and education material
                        distributed.
                      </p>
                    </li>

                    <li>
                      <p>
                        Different pre-intervention studies on lung cancer
                        diagnosis, treatment and care were successfully
                        completed and they are informing clinical interventions.
                      </p>
                    </li>

                    <li>
                      <p>
                        Hospital cancer surveillance/registration in three
                        institutions was successfully initiated.
                      </p>
                    </li>

                    <li>
                      <p>
                        Community health workers were trained on palliative care
                        and data collection.
                      </p>
                    </li>

                    <li>
                      <p>
                        Successful capacity building in cancer research of young
                        researchers.
                      </p>
                    </li>

                    <li>
                      <p>
                        A fully equipped pulmonology clinic that will diagnose
                        lung cancer was successfully established.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="bottom-logos-section">
                  <div>
                    <Alert color="dark">
                      <div className="d-flex">
                        <img className="ao_img" src={imgUrl.ao_badge_small} />
                        <div className="ao_text">
                          <p>
                            The data for this program has been reviewed by both
                            the companies and the Access Observatory team.
                          </p>
                          <a
                            href="https://www.accessobservatory.org/"
                            target="_blank"
                          >
                            https://www.accessobservatory.org/
                          </a>
                        </div>
                      </div>
                    </Alert>
                    <Alert color="" className="mb-0">
                      <div className="d-flex">
                        <img className="aa_img" src={imgUrl.aa_in_action} />
                        <div className="aa_text">
                          <p>
                            Access Accelerated in Action articulates the six
                            cross-cutting principles that underpin the
                            initiative’s commitment to strengthening NCD
                            programs.{" "}
                          </p>
                          <a href="#">Read the full report</a>
                        </div>
                      </div>
                    </Alert>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="8" className="pl-0">
              <div className="discriptionSection">
                {/* var URL=`https://login.microsoftonline.com/${tenantId}/oauth2/token` */}
                <div
                  className="blacksection"
                  style={{ backgroundImage: `url(${program_logo})` }}
                ></div>
                <div className="desp-wrap">
                  <div className="collabration-wrap">
                    <h5>Collaboration Description</h5>
                    <div className="colb-desp-summary">
                      <>
                        <div
                          dangerouslySetInnerHTML={{ __html: Program_desc }}
                        ></div>
                      </>
                    </div>
                  </div>
                  <div className="contentDiv mt-3">
                    <Row>
                      <Col md="4">
                        <div className="ncd_focus_div">
                          <h5>NCD Focus</h5>
                          <div className="ncd_focus_content">
                            <div className="ncd_img_div">
                              {/* <img className="w-100" src={imgUrl.ncd_img} /> */}
                              <img className="w-100" src={active_ncds_url} />
                            </div>
                            <textarea className="w-100 mt-2 h-75">
                              {/* <img className="w-100" src={imgUrl.ncd_img} /> */}
                              {/* <img className="w-100" src={active_ncds_url} /> */}
                            </textarea>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="sdgs_div">
                          <h5>
                            SDG<span>s</span> Contribution
                          </h5>
                          <div className="sdgs_content">
                            <div className="d-flex">
                              <div className="sdgs_img_wrap position-relative">
                                {/* <img className="" src={imgUrl.donut} />
                                  <p className="donut_inside_text">SDGs</p> */}

                                {/* <div dangerouslySetInnerHTML={ { __html: SDG_Logo } }></div> */}
                                {SDG_A &&
                                  SDG_A.length > 0 &&
                                  SDG_A.map(
                                    (intervent) =>
                                      // <div>

                                      {
                                        if (SDG_A != "N/A") {
                                          return (
                                            <img
                                              className="intervation_img"
                                              src={intervent}
                                            />
                                          );
                                        } else {
                                          return "N/A";
                                        }
                                      }
                                    // </div>
                                  )}
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="key_demo_div">
                          <h5>Key Demographics</h5>
                          <div className="key_demo_content">
                            <div className="h-75 key_tags_wrap mb-2">
                              <Button
                                color="secondary"
                                size="sm"
                                className="purple_btn"
                              >
                                Female
                              </Button>{" "}
                              <Button
                                color="secondary"
                                size="sm"
                                className="orange_btn"
                              >
                                Children
                              </Button>{" "}
                              <p className="w-75 text-center m-auto mb-0 text-black">
                                This program does not target a specific
                                demograohic
                              </p>
                              {beneficiaries_gender == null
                                ? "N/A"
                                : beneficiaries_gender}
                              <br />
                              {target_populations == null
                                ? "N/A"
                                : target_populations}
                            </div>
                            <Button
                              color="secondary"
                              size="sm"
                              className="purple_btn"
                            >
                              Gender
                            </Button>{" "}
                            <Button
                              color="secondary"
                              size="sm"
                              className="blue_btn"
                            >
                              Special populations
                            </Button>{" "}
                            <Button
                              color="secondary"
                              size="sm"
                              className="orange_btn"
                            >
                              Age groups
                            </Button>{" "}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="people_count">
                          <h5 className="contentDiv-header">
                            People Reached through the Continuum of Care
                          </h5>
                          <div className="people_count_content">
                            {/* <BootstrapTable keyField='id' data={ products_COC } columns={ columnsCOC } /> */}
                            <Table size="sm" bordered>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      backgroundColor: "#fff",
                                    }}
                                  >
                                    Reporting period
                                  </th>
                                  <td>2017</td>
                                  <td>2018</td>
                                  <td>2019</td>
                                  <td>2020</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th style={{ color: "#D2342A" }}>
                                    Awareness and prevention
                                  </th>
                                  <td>{Awareness[0]}</td>
                                  <td>{Awareness[1]}</td>
                                  <td>{Awareness[2]}</td>
                                  <td>{Awareness[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#A5247A" }}>
                                    Screening
                                  </th>
                                  <td>{Screening[0]}</td>
                                  <td>{Screening[1]}</td>
                                  <td>{Screening[2]}</td>
                                  <td>{Screening[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#82B450" }}>
                                    Diagnosis
                                  </th>
                                  <td>{Diagnosis[0]}</td>
                                  <td>{Diagnosis[1]}</td>
                                  <td>{Diagnosis[2]}</td>
                                  <td>{Diagnosis[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#25A0C3" }}>
                                    Treatment
                                  </th>
                                  <td>{Treatment[0]}</td>
                                  <td>{Treatment[1]}</td>
                                  <td>{Treatment[2]}</td>
                                  <td>{Treatment[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#FAA73F" }}>
                                    Long term & After care
                                  </th>
                                  <td>{LongTerm[0]}</td>
                                  <td>{LongTerm[1]}</td>
                                  <td>{LongTerm[2]}</td>
                                  <td>{LongTerm[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#82B450" }}>Other</th>
                                  <td>{Others[0]}</td>
                                  <td>{Others[1]}</td>
                                  <td>{Others[2]}</td>
                                  <td>{Others[3]}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#25A0C3" }}>
                                    Health system strenthening
                                  </th>
                                  <td>{Health[0]}</td>
                                  <td>{Health[1]}</td>
                                  <td>{Health[2]}</td>
                                  <td>{Health[3]}</td>
                                </tr>
                              </tbody>
                            </Table>
                            <p className="no_value_msg">
                              No values indicated pending information
                            </p>
                            <a
                              className="viewClasstext text-right w-100 d-block"
                              onClick={toggle}
                            >
                              View all values
                            </a>

                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              className={className}
                            >
                              <ModalHeader toggle={toggle}>
                                Modal title
                              </ModalHeader>
                              <ModalBody>
                                <Table size="sm" bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "right",
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        Reporting period
                                      </th>
                                      <td>2017</td>
                                      <td>2018</td>
                                      <td>2019</td>
                                      <td>2020</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* { COC_Cate && COC_Cate.length>0 && COC_Cate.map((coc_cat)=>
                                 <> */}
                                    <tr>
                                      <th style={{ color: "#D2342A" }}>
                                        Awareness and prevention
                                      </th>
                                      <td>{Awareness[0]}</td>
                                      <td>{Awareness[1]}</td>
                                      <td>{Awareness[2]}</td>
                                      <td>{Awareness[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#A5247A" }}>
                                        Screening
                                      </th>
                                      <td>{Screening[0]}</td>
                                      <td>{Screening[1]}</td>
                                      <td>{Screening[2]}</td>
                                      <td>{Screening[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#82B450" }}>
                                        Diagnosis
                                      </th>
                                      <td>{Diagnosis[0]}</td>
                                      <td>{Diagnosis[1]}</td>
                                      <td>{Diagnosis[2]}</td>
                                      <td>{Diagnosis[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#25A0C3" }}>
                                        Treatment
                                      </th>
                                      <td>{Treatment[0]}</td>
                                      <td>{Treatment[1]}</td>
                                      <td>{Treatment[2]}</td>
                                      <td>{Treatment[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#FAA73F" }}>
                                        Long term & After care
                                      </th>
                                      <td>{LongTerm[0]}</td>
                                      <td>{LongTerm[1]}</td>
                                      <td>{LongTerm[2]}</td>
                                      <td>{LongTerm[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#82B450" }}>
                                        Other
                                      </th>
                                      <td>{Others[0]}</td>
                                      <td>{Others[1]}</td>
                                      <td>{Others[2]}</td>
                                      <td>{Others[3]}</td>
                                    </tr>
                                    <tr>
                                      <th style={{ color: "#25A0C3" }}>
                                        Health system strenthening
                                      </th>
                                      <td>{Health[0]}</td>
                                      <td>{Health[1]}</td>
                                      <td>{Health[2]}</td>
                                      <td>{Health[3]}</td>
                                    </tr>
                                    {/*                                 
                              </>
                                )} */}
                                  </tbody>
                                </Table>
                              </ModalBody>
                              <ModalFooter>
                                <Button color="secondary" onClick={toggle}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="catchment_area_div">
                          <h5 className="contentDiv-header">Catchment areas</h5>
                          <div className="catchment_area_content">
                            {/* {catchement_area1}
                                {catchement_area2} */}
                            <div>
                              {/* <HighchartsReact
                                    options={mapOptions2}
                                    constructorType={'mapChart'}
                                    highcharts={Highcharts}
                                  /> */}
                              <div>
                                {catchment_Country}
                                <br />
                                <br />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: catchment_area,
                                  }}
                                ></div>
                                {/* {catchment_area} */}
                                {/* <MapChart /> */}
                              </div>
                              {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
                              {/* <div id="world_map" /> */}
                              {/* <HighchartsReact options={mapOptions} constructorType={'mapChart'} highcharts={Highcharts} /> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="program_strat_div">
                          <h5 className="contentDiv-header">
                            Program Strategies
                          </h5>
                          <div className="program_strat_div">
                            <Table size="sm" borderless>
                              <tbody>
                                {StrategiesArray &&
                                  StrategiesArray.length > 0 &&
                                  StrategiesArray.map((strg) => (
                                    <tr>
                                      {/* <td style={{ width: "10%" }}><img className="w-auto" src={imgUrl.group_4974} /></td> */}
                                      <td style={{ width: "10%" }}>
                                        <img
                                          src={strg.strategy_logo}
                                          style={{ width: "72px" }}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          backgroundColor: "#F5F5F5",
                                          padding: "2px 10px",
                                        }}
                                      >
                                        {/* <p>Community Awareness and Linkage to Care</p> */}
                                        <p>{strg.strategy_name}</p>
                                        {strg.activities &&
                                          strg.activities.length > 0 &&
                                          strg.activities.map((strg_desc) => {
                                            if (
                                              strg_desc.Activity_desc != null
                                            ) {
                                              return (
                                                <Button
                                                  color="secondary"
                                                  size="sm"
                                                  style={{ marginRight: "5px" }}
                                                  className="prog_btn"
                                                >
                                                  {strg_desc.Activity_desc}
                                                </Button>
                                              );
                                            } else {
                                              return "";
                                            }
                                          })}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>

                            <a className="viewClasstext text-right w-100 d-block">
                              View all strategies
                            </a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="beneficiary_area_div">
                          <h5 className="contentDiv-header">
                            Beneficiary Population
                          </h5>
                          <div className="beneficiary_area_content">
                            <p className="mb-1">
                              This program contributes to the{" "}
                              <span className="orangeText">
                                Beneficiary Population
                              </span>
                              * with:
                            </p>
                            <Alert color="secondary" className="dataCount">
                              {/* 3.261K */}
                              {/* {total_population_served1} */}
                              {FormattedPopulation}
                            </Alert>
                            <p className="mb-1">
                              *People living within the catchment area
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <div className="partners_wrap">
                          <h5 className="contentDiv-header">
                            Implementing Partners
                          </h5>
                          <div className="partners_content">
                            {/* <Styles>
                              <Table columns={columns} data={data_table}  />
                            </Styles> */}
                            <BootstrapTable
                              keyField="id"
                              data={products}
                              columns={columns}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <footer>
          <Row>
            <Col>
              <div className="float-right">
                <div className="footerDivWrap">
                  <h5>AA Open Platform 2021 -</h5>
                  <img className="" src={imgUrl.dureLogo} />
                </div>
              </div>
            </Col>
          </Row>
        </footer>
      </Container>
    </div>
  );
}

export default ProgramProfiles;
