import React from "react";
import { Table } from 'reactstrap';


export default function ProgramStrategy(strgData) {
    const Data = strgData.strgData;
    console.log('strgData',Data);
    return (
        <Table size="sm" borderless>
        <tbody>
          {Data
            ? Data.length > 0 &&
            Data.map(
                (strg) => (
                  <tr>
                    <td
                      style={{ width: "10%" }}
                    >
                      <img
                        src={
                          strg.strategy_logo
                        }
                        style={{
                          width: "48px",
                        }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          "#F5F5F5",
                        padding: "2px 10px",
                      }}
                    >
                      <p className="program_strat_div_p">
                        {strg.strategy_name}
                      </p>
                    </td>
                  </tr>
                )
              )
            : ""}
        </tbody>
      </Table>   
    );
}